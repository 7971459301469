import { ref, computed, defineComponent, watch } from 'vue'

export default defineComponent({
  name: 'vip-inlichting-accordion',
  props: {
    open: Boolean,
    title: {
      required: true,
      type: String
    },
    modAltHeader: {
      type: Boolean,
      default: false
    },
    modShowHeader: {
      type: Boolean,
      default: false
    },
    modOnlyContent: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const length = ref(0) // Fix random length is not defined error
    const opened = ref(props.open)

    const classes = computed(() => [
      'vip-inlichting', {
        'vip-inlichting--open': opened.value,
        'vip-inlichting--accordion': props.modShowHeader,
        'vip-inlichting--alt': props.modShowHeader && props.modAltHeader
      }
    ])

    const headerToggleComponent = computed(() => {
      return props.modShowHeader ? 'button' : 'div'
    })

    const toggle = () => {
      if (props.modShowHeader) {
        opened.value = !opened.value
        emit('inlichting-toggled', opened.value)
      }
    }

    watch(() => props.open, (newVal) => {
      opened.value = newVal
    })

    return {
      opened,
      classes,
      headerToggleComponent,
      length,
      toggle
    }
  }
})
