import { computed, defineComponent, PropType, ref } from 'vue'
import VipInlichtingVanToepassing
  from '@/components/vip-inlichting/components/vip-inlichting-van-toepassing/vip-inlichting-van-toepassing.vue'
import {
  BrownfieldInlichting,
  DossierStatus,
  Inlichting,
  TypeInlichting
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import VipInlichtingAccordion
  from '@/components/vip-inlichting/components/vip-inlichting-accordion/vip-inlichting-accordion.vue'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'

export default defineComponent({
  name: 'Brownfield',
  components: { VipInlichtingVanToepassing, VipInlichtingPreviewNew },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props) {
    const title = ref(null)
    const vipInlichting = ref(props.inlichting as BrownfieldInlichting)
    const accordion = ref<InstanceType<typeof VipInlichtingAccordion>>(null)
    const inlichtingType = TypeInlichting.Brownfield
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)

    const getLabel = (option: string): string => {
      return new Map([
        ['AANVRAAG_INGETROKKEN', 'Aanvraag ingetrokken'],
        ['DEFINITIEVE_CONVENANT', 'Definitief'],
        ['GEGROND_ONDERHANDELINGEN_OPGESTART', 'Gegrond / onderhandelingen opgestart'],
        ['GEREALISEERDE_CONVENANT', 'Gerealiseerd'],
        ['INGEDIEND', 'Ingediend'],
        ['ONDERHANDELINGEN_STOPGEZET', 'Onderhandelingen stopgezet'],
        ['ONGEGROND', 'Ongegrond'],
        ['ONONTVANKELIJK', 'Onontvankelijk'],
        ['ONTWERPCONVENANT_INSPRAAKVERGADERING', 'Ontwerpconvenant / inspraakvergadering']
      ]).get(option)
    }

    return {
      title,
      inlichtingType,
      vipInlichting,
      accordion,
      inlichtingTitle,
      getLabel
    }
  }
})
