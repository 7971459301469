import { computed, defineComponent, PropType } from 'vue'
import {
  BronInformatie,
  DossierStatus,
  InlichtingenIndicator,
  InlichtingInputInlichtingenIndicator,
  InlichtingRubriek,
  ProcesVerbaalBeslissingParket,
  StedenbouwkundigeOvertredingInlichting,
  StedenbouwkundigeOvertredingInlichtingGerelateerdeWetgeving,
  StedenbouwkundigeOvertredingInlichtingInlichtingType,
  StedenbouwkundigeOvertredingInput,
  StedenbouwkundigeOvertredingInputDiscriminator,
  StedenbouwkundigeOvertredingInputGerelateerdeWetgeving,
  StedenbouwkundigeOvertredingInputInlichtingType, TypeInlichting
} from '@/infrastructure/bff-client/bff-client'
import useEditableInlichting from '@/components/vip-inlichting/new-version/composables/editable-inlichting.composable'
import VipInlichtingVanToepassing
  from '@/components/vip-inlichting/components/vip-inlichting-van-toepassing/vip-inlichting-van-toepassing.vue'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import EventBus from '@/infrastructure/events/event-bus'
import { inlichtingen, InlichtingType } from '@/infrastructure/constants/inlichting-types-constants'
import useStedenbouwkundigeOvertredingLabels
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/composable/stedenbouwkundige-overtreding-labels.composable'
import StedenbouwkundigeOvertredingEdit
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/edit/stedenbouwkundige-overtreding-edit.vue'
import StedenbouwkundigeOvertredingPreview
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/preview/stedenbouwkundige-overtreding-preview.vue'

function mapInlichtingToInput (vipInlichting?: StedenbouwkundigeOvertredingInlichting): StedenbouwkundigeOvertredingInput {
  const copy = structuredClone(vipInlichting)
  return {
    inlichtingenIndicator: copy === null ? InlichtingInputInlichtingenIndicator.NEE : copy.inlichtingenIndicator as unknown as InlichtingInputInlichtingenIndicator,
    omschrijving: copy?.omschrijving,
    referentie: copy?.referentie,
    gemeentelijkDossiernummer: copy?.gemeentelijkDossiernummer,
    overtreding: copy?.overtreding,
    gerelateerdeWetgeving: copy?.gerelateerdeWetgeving as unknown as StedenbouwkundigeOvertredingInputGerelateerdeWetgeving,
    misdrijven: copy?.misdrijven ?? [],
    inbreuken: copy?.inbreuken ?? [],
    externeDocumentatie: copy?.externeDocumentatie ?? [],
    discriminator: StedenbouwkundigeOvertredingInputDiscriminator.STEDENBOUWKUNDIGE_OVERTREDING_V1,
    inlichtingType: StedenbouwkundigeOvertredingInputInlichtingType.STEDENBOUWKUNDIGE_OVERTREDING
  } as StedenbouwkundigeOvertredingInput
}

function mapInputToInlichting (input: StedenbouwkundigeOvertredingInput, inlichtingId: string): StedenbouwkundigeOvertredingInlichting {
  const copy = structuredClone(input)
  return {
    inlichtingId,
    inlichtingenIndicator: copy.inlichtingenIndicator as unknown as InlichtingenIndicator,
    inlichtingType: StedenbouwkundigeOvertredingInlichtingInlichtingType.STEDENBOUWKUNDIGE_OVERTREDING,
    externeDocumentatie: copy.externeDocumentatie,
    bronInformatie: {
      informatieverstrekker: 'Lokaal bestuur',
      laatsteWijzigingTijdstip: new Date()
    } as BronInformatie,
    kaarten: [],
    rubriek: InlichtingRubriek.VERGUNNINGEN,
    referentie: copy.referentie,
    gemeentelijkDossiernummer: copy.gemeentelijkDossiernummer,
    omschrijving: copy.omschrijving,
    overtreding: copy.overtreding,
    gerelateerdeWetgeving: copy.gerelateerdeWetgeving as unknown as StedenbouwkundigeOvertredingInlichtingGerelateerdeWetgeving,
    misdrijven: copy.misdrijven,
    inbreuken: copy.inbreuken
  } as StedenbouwkundigeOvertredingInlichting
}

export default defineComponent({
  name: 'stedenbouwkundige-overtreding',
  components: {
    StedenbouwkundigeOvertredingEdit,
    StedenbouwkundigeOvertredingPreview,
    VipInlichtingVanToepassing,
  },
  props: {
    inlichting: {
      type: Object as PropType<StedenbouwkundigeOvertredingInlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props, { emit }) {
    const {
      refs,
      vipInlichting,
      inlichtingInput,
      canEdit,
      validationErrors,
      addedBijlagen,
      inlichtingBijlagen,
      accordion,
      saving,
      inlichtingKey,
      isNietGekend,
      bijlageUploaded,
      toggleIndicator,
      inlichtingToggled,
      cancelEdit,
      saveInlichting
    } = useEditableInlichting(
      props.inlichting ??
        ({ inlichtingType: InlichtingType.STEDENBOUWKUNDIGE_OVERTREDING } as unknown as StedenbouwkundigeOvertredingInlichting),
      mapInlichtingToInput,
      mapInputToInlichting
    )

    const { options, getLabel } = useStedenbouwkundigeOvertredingLabels()

    const inlichtingType = TypeInlichting.StedenbouwkundigeOvertreding
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)

    const validateInput = (): Map<string, string> => {
      const errors = new Map<string, any>()
      const inputToCheck = inlichtingInput.value as StedenbouwkundigeOvertredingInput
      if (inputToCheck.inlichtingenIndicator === InlichtingInputInlichtingenIndicator.JA) {
        if (!!inputToCheck.omschrijving === false) {
          errors.set('beschrijving', true)
        }
        if (!!inputToCheck.referentie === false) {
          errors.set('dossiernummer', true)
        }
        if (inputToCheck.gerelateerdeWetgeving == null) {
          errors.set('gerelateerdeWetgeving', 'Keuze overtreding voor of na inwerkingtreding Omgevingsvergunningdecreet is verplicht')
        } else if (inputToCheck.gerelateerdeWetgeving === StedenbouwkundigeOvertredingInputGerelateerdeWetgeving.NA_OMGEVINGSVERGUNNINGENDECREET) {
          if ((inputToCheck.misdrijven?.length ?? 0) === 0 && (inputToCheck.inbreuken?.length ?? 0) === 0) {
            errors.set('procedures', 'U moet minimaal 1 misdrijf of inbreuk toevoegen')
          }
        } else if (inputToCheck.gerelateerdeWetgeving === StedenbouwkundigeOvertredingInputGerelateerdeWetgeving.VOOR_OMGEVINGSVERGUNNINGENDECREET) {
          if ((inputToCheck.misdrijven?.length ?? 0) === 0) {
            errors.set('procedures', 'U moet minimaal 1 misdrijf toevoegen')
          }
        }
        inputToCheck.misdrijven.forEach((misdrijf, procedureIndex) => {
          misdrijf.aanmaningen?.forEach((aanmaning, aanmaningIndex) => {
            if (!!aanmaning.opsteller === false) {
              errors.set(`procedure-${procedureIndex}-aanmaning-${aanmaningIndex}-opsteller`, true)
            }
            if (!!aanmaning.verzenddatum === false) {
              errors.set(`procedure-${procedureIndex}-aanmaning-${aanmaningIndex}-verzend-datum`, true)
            }
          })
          misdrijf.bestuurlijkeHerstelmaatregelen?.forEach((herstelmaatregel, herstelmaatregelIndex) => {
            if (!!herstelmaatregel.omschrijving === false) {
              errors.set(`procedure-${procedureIndex}-herstelmaatregel-${herstelmaatregelIndex}-omschrijving`, true)
            }
            if (!!herstelmaatregel.instantie === false) {
              errors.set(`procedure-${procedureIndex}-herstelmaatregel-${herstelmaatregelIndex}-instantie`, true)
            }
            if (!!herstelmaatregel.datum === false) {
              errors.set(`procedure-${procedureIndex}-herstelmaatregel-${herstelmaatregelIndex}-datum`, true)
            }
          })
          if (misdrijf.stakingsbevel != null) {
            if (!!misdrijf.stakingsbevel.datumBevel === false) {
              errors.set(`procedure-${procedureIndex}-stakingsbevel-datum`, true)
            }
          }
          if (misdrijf.minnelijkeSchikking != null) {
            if (!!misdrijf.minnelijkeSchikking.omschrijving === false) {
              errors.set(`procedure-${procedureIndex}-minnelijke-schikking-omschrijving`, true)
            }
          }
          misdrijf.processenVerbaal?.forEach((procesVerbaal, procesVerbaalIndex) => {
            if (!!procesVerbaal.nummer === false) {
              errors.set(`procedure-${procedureIndex}-proces-verbaal-${procesVerbaalIndex}-nummer`, true)
            }
            if (procesVerbaal.beslissingParket === ProcesVerbaalBeslissingParket.SEPONERING) {
              if (!!procesVerbaal.seponering.reden === false) {
                errors.set(`procedure-${procedureIndex}-proces-verbaal-${procesVerbaalIndex}-seponering-reden`, true)
              }
              if (!!procesVerbaal.seponering.datum === false) {
                errors.set(`procedure-${procedureIndex}-proces-verbaal-${procesVerbaalIndex}-seponering-datum`, true)
              }
            } else if (procesVerbaal.beslissingParket === ProcesVerbaalBeslissingParket.VERVOLGING) {
              if (!!procesVerbaal.vervolging.vorderingInstantie === false) {
                errors.set(`procedure-${procedureIndex}-proces-verbaal-${procesVerbaalIndex}-vervolging-vordering-instantie`, true)
              }
              if (!!procesVerbaal.vervolging.datum === false) {
                errors.set(`procedure-${procedureIndex}-proces-verbaal-${procesVerbaalIndex}-vervolging-datum`, true)
              }
              if (!!procesVerbaal.vervolging.omschrijving === false) {
                errors.set(`procedure-${procedureIndex}-proces-verbaal-${procesVerbaalIndex}-vervolging-omschrijving`, true)
              }
              if (procesVerbaal.vervolging.uitsprakenRechterlijkeInstantie != null) {
                let uitspraakIndex = 0
                if (procesVerbaal.vervolging.uitsprakenRechterlijkeInstantie.adviesHogeRaadVoorHandhavingsuitvoering != null) {
                  if (!!procesVerbaal.vervolging.uitsprakenRechterlijkeInstantie.adviesHogeRaadVoorHandhavingsuitvoering?.soortAdvies === false) {
                    errors.set(`procedure-${procedureIndex}-proces-verbaal-${procesVerbaalIndex}-uitspraak-${uitspraakIndex}-rechtbank-handhavingsuitvoering-advies`, true)
                  }
                  if (!!procesVerbaal.vervolging.uitsprakenRechterlijkeInstantie.adviesHogeRaadVoorHandhavingsuitvoering?.datum === false) {
                    errors.set(`procedure-${procedureIndex}-proces-verbaal-${procesVerbaalIndex}-uitspraak-${uitspraakIndex}-rechtbank-handhavingsuitvoering-datum`, true)
                  }
                  uitspraakIndex++
                }
                if ((procesVerbaal.vervolging.uitsprakenRechterlijkeInstantie.rechtbankenVanEersteAanleg?.length ?? 0) > 0) {
                  procesVerbaal.vervolging.uitsprakenRechterlijkeInstantie.rechtbankenVanEersteAanleg.forEach((uitspraak, index) => {
                    const currentIndex = uitspraakIndex + index
                    if (!!uitspraak.omschrijving === false) {
                      errors.set(`procedure-${procedureIndex}-proces-verbaal-${procesVerbaalIndex}-uitspraak-${currentIndex}-rechtbank-eerste-aanleg-vonnis`, true)
                    }
                    if (!!uitspraak.datumVonnis === false) {
                      errors.set(`procedure-${procedureIndex}-proces-verbaal-${procesVerbaalIndex}-uitspraak-${currentIndex}-rechtbank-eerste-aanleg-datum`, true)
                    }
                  })
                  uitspraakIndex += procesVerbaal.vervolging.uitsprakenRechterlijkeInstantie.rechtbankenVanEersteAanleg.length
                }
                if ((procesVerbaal.vervolging.uitsprakenRechterlijkeInstantie.hovenVanBeroep?.length ?? 0) > 0) {
                  procesVerbaal.vervolging.uitsprakenRechterlijkeInstantie.hovenVanBeroep.forEach((uitspraak, index) => {
                    const currentIndex = uitspraakIndex + index
                    if (!!uitspraak.datumBeroepIngediend === false) {
                      errors.set(`procedure-${procedureIndex}-proces-verbaal-${procesVerbaalIndex}-uitspraak-${currentIndex}-rechtbank-hof-beroep-ingediend`, true)
                    }
                  })
                }
              }
            }
          })
        })
        const inbreukStartIndex = inputToCheck.misdrijven?.length ?? 0
        inputToCheck.inbreuken.forEach((inbreuk, index) => {
          const procedureIndex = inbreukStartIndex + index
          if (!!inbreuk.verslagVanVaststelling.nummer === false) {
            errors.set(`inbreuk-${procedureIndex}-verslag-vastelling-nummer`, true)
          }
          if (!!inbreuk.verslagVanVaststelling.datum === false) {
            errors.set(`inbreuk-${procedureIndex}-verslag-vastelling-datum`, true)
          }
          inbreuk.aanmaningen?.forEach((aanmaning, aanmaningIndex) => {
            if (!!aanmaning.opsteller === false) {
              errors.set(`procedure-${procedureIndex}-aanmaning-${aanmaningIndex}-opsteller`, true)
            }
            if (!!aanmaning.verzenddatum === false) {
              errors.set(`procedure-${procedureIndex}-aanmaning-${aanmaningIndex}-verzend-datum`, true)
            }
          })
          inbreuk.bestuurlijkeHerstelmaatregelen?.forEach((herstelmaatregel, herstelmaatregelIndex) => {
            if (!!herstelmaatregel.omschrijving === false) {
              errors.set(`procedure-${procedureIndex}-herstelmaatregel-${herstelmaatregelIndex}-omschrijving`, true)
            }
            if (!!herstelmaatregel.instantie === false) {
              errors.set(`procedure-${procedureIndex}-herstelmaatregel-${herstelmaatregelIndex}-instantie`, true)
            }
            if (!!herstelmaatregel.datum === false) {
              errors.set(`procedure-${procedureIndex}-herstelmaatregel-${herstelmaatregelIndex}-datum`, true)
            }
            if (!!herstelmaatregel.type === false) {
              errors.set(`procedure-${procedureIndex}-herstelmaatregel-${herstelmaatregelIndex}-type`, true)
            }
          })
          if (inbreuk.stakingsbevel != null) {
            if (!!inbreuk.stakingsbevel.datumBevel === false) {
              errors.set(`procedure-${procedureIndex}-stakingsbevel-datum`, true)
            }
          }
          if (inbreuk.minnelijkeSchikking != null) {
            if (!!inbreuk.minnelijkeSchikking.omschrijving === false) {
              errors.set(`procedure-${procedureIndex}-minnelijke-schikking-omschrijving`, true)
            }
          }
        })
      }
      return errors
    }

    const validationFailed = (errors: Map<string, string>) => {
      validationErrors.value = errors
    }

    const inputUpdated = (input: StedenbouwkundigeOvertredingInput) => {
      inlichtingInput.value = input
    }

    const saved = (inlichtingId: string) => {
      saveInlichting(inlichtingId)
      emit('saved', vipInlichting.value)
      inlichtingToggled(true)
    }

    const removed = () => {
      emit('removed', vipInlichting.value.inlichtingId)
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const cancelled = () => {
      cancelEdit()
      emit('cancelled')
    }

    return {
      vipInlichting,
      inlichtingInput,
      validationErrors,
      saving,
      accordion,
      refs,
      inlichtingKey,
      inlichtingBijlagen,
      canEdit,
      addedBijlagen,
      isNietGekend,
      inlichtingType,
      bijlageUploaded,
      inlichtingToggled,
      toggleIndicator,
      validateInput,
      validationFailed,
      saved,
      removed,
      cancelled,
      formatNumber,
      getLabel,
      formatDate,
      inputUpdated,
      StedenbouwkundigeOvertredingInlichtingInlichtingType,
      inlichtingTitle,
      options
    }
  }
})
