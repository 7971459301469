import { computed, defineComponent, PropType, ref } from 'vue'
import {
  DossierStatus,
  Inlichting, NatuurbeheerplanInlichting,
  TypeInlichting
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import { formatDate } from '@/infrastructure/filters/filters'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'

export default defineComponent({
  name: 'Natuurbeheerplan',
  components: { VipInlichtingPreviewNew },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props) {
    const previewData = ref(props.inlichting as NatuurbeheerplanInlichting)
    const inlichtingType = TypeInlichting.Natuurbeheerplan
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)

    const getLabel = (option: string): string => {
      return new Map([
        ['BEPERKT_BOSBEHEERPLAN', 'Beperkt bosbeheerplan'],
        ['UITGEBREID_BOSBEHEERPLAN', 'Uitgebreid bosbeheerplan'],
        ['AANGEWEZEN_BOSRESERVAAT', 'Aangewezen bosreservaat'],
        ['ERKEND_BOSRESERVAAT', 'Erkend bosreservaat'],
        ['ERFGOEDBEHEERPLAN', 'Erfgoedbeheerplan'],
        ['HARMONISCH_PARK_EN_GROENBEHEERPLAN', 'Harmonisch Park- en Groenbeheerplan'],
        ['BEHEERPLAN_MILITAIR_DOMEIN', 'Beheerplan Militair Domein'],
        ['BEHEERPLAN_TYPE_1', 'Natuurbeheerplan Type 1: Behoud van de aanwezige natuurkwaliteit'],
        ['BEHEERPLAN_TYPE_2', 'Natuurbeheerplan Type 2: Hogere natuurkwaliteit'],
        ['BEHEERPLAN_TYPE_3', 'Natuurbeheerplan Type 3: Hoogste natuurkwaliteit'],
        ['BEHEERPLAN_TYPE_4', 'Natuurbeheerplan Type 4: Natuurreservaat'],
        ['AANGEWEZEN_NATUURRESERVAAT', 'Aangewezen Natuurreservaat'],
        ['ERKEND_NATUURRESERVAAT', 'Erkend Natuurreservaat'],
        ['VLAAMS_NATUURRESERVAAT', 'Vlaams Natuurreservaat'],
        ['ANDERE_BEHEERPLANNEN', 'Andere beheerplannen'],
      ]).get(option)
    }

    return {
      previewData,
      inlichtingType,
      inlichtingTitle,
      formatDate,
      getLabel
    }
  }
})
