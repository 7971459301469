import { computed, defineComponent, PropType, ref } from 'vue'
import {
  DossierStatus,
  Inlichting, OverstromingsgevoeligheidInlichtingV2,
  TypeInlichting
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import { formatDate } from '@/infrastructure/filters/filters'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'
import OverstromingsgevoeligheidScore
  from '@/components/vip-inlichting/new-version/components/inlichtingen/natuur/overstromingsgevoeligheid/components/overstromingsgevoeligheid-score.vue'

export default defineComponent({
  name: 'Overstromingsgevoeligheid',
  components: { VipInlichtingPreviewNew, OverstromingsgevoeligheidScore },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props) {
    const previewData = ref(props.inlichting as OverstromingsgevoeligheidInlichtingV2)
    const inlichtingType = TypeInlichting.OverstromingsgevoeligheidV2
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)

    const getLabel = (option: string): string => {
      return new Map([
        ['A', 'A - Geen overstroming gemodelleerd'],
        ['B', 'B - Kleine kans op overstromingen onder klimaatverandering'],
        ['C', 'C - Kleine kans op overstromingen'],
        ['D', 'D - Middelgrote kans op overstromingen'],
      ]).get(option)
    }

    return {
      previewData,
      inlichtingType,
      inlichtingTitle,
      formatDate,
      getLabel
    }
  }
})
