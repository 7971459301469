import { computed, defineComponent, PropType, ref } from 'vue'
import {
  DossierStatus,
  Inlichting,
  TypeInlichting, ZoneringsplanInlichting
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'
import vipInlichting from '../../../../../vip-inlichting'

export default defineComponent({
  name: 'Zoneringsplan',
  computed: {
    vipInlichting () {
      return vipInlichting
    }
  },
  components: { VipInlichtingPreviewNew },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props) {
    const previewData = ref(props.inlichting as ZoneringsplanInlichting)
    const inlichtingType = TypeInlichting.Zoneringsplan
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)

    const getLabel = (option: string): string => {
      return new Map([
        ['CENTRAAL_GEBIED', 'Centraal gebied'],
        ['COLLECTIEF_GEOPTIMALISEERD_BUITENGEBIED', 'Collectief geoptimaliseerd buitengebied'],
        ['COLLECTIEF_TE_OPTIMALISEREN_BUITENGEBIED', 'Collectief te optimaliseren buitengebied'],
        ['INDIVIDUEEL_TE_OPTIMALISEREN_BUITENGEBIED_GESLOTEN_OPVANGSYSTEEM_AANWEZIG', 'Individueel te optimaliseren buitengebied - gesloten opvangsysteem aanwezig'],
        ['INDIVIDUEEL_TE_OPTIMALISEREN_BUITENGEBIED_GESLOTEN_OPVANGSYSTEEM_GEPLAND', 'Individueel te optimaliseren buitengebied - gesloten opvangsysteem gepland'],
        ['INDIVIDUEEL_TE_OPTIMALISEREN_BUITENGEBIED_IBA_AANWEZIG', 'Individueel te optimaliseren buitengebied - IBA aanwezig'],
        ['INDIVIDUEEL_TE_OPTIMALISEREN_BUITENGEBIED_IBA_GEPLAND', 'Individueel te optimaliseren buitengebied - IBA gepland'],
      ]).get(option)
    }

    return {
      previewData,
      inlichtingType,
      inlichtingTitle,
      getLabel
    }
  }
})
