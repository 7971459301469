import {
  Inbreuk,
  Misdrijf, StedenbouwkundigeOvertredingInput,
  StedenbouwkundigeOvertredingInputGerelateerdeWetgeving
} from '@/infrastructure/bff-client/bff-client'
import { computed, defineComponent, onBeforeMount, PropType, ref, watch } from 'vue'
import CollectionItem
  from '@/components/vip-inlichting/new-version/components/inlichting-edit/components/collection-item/collection-item.vue'
import ProcedureEdit
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/edit/procedure/procedure-edit.vue'
import EventBus from '@/infrastructure/events/event-bus'

export enum ProcedureType {
  Misdrijf = 'misdrijf',
  Inbreuk = 'inbreuk'
}
export interface Procedure {
  type: ProcedureType,
  procedure: Misdrijf | Inbreuk
}

export default defineComponent({
  name: 'procedures-edit',
  components: {
    CollectionItem,
    ProcedureEdit
  },
  props: {
    input: Object as PropType<StedenbouwkundigeOvertredingInput>,
    modDisabled: Boolean,
    validationErrors: Map<string, string>
  },
  setup (props, { emit }) {
    const loaded = ref(false)
    const indexToRemove = ref(null)
    const procedureItems = ref([] as Procedure[])

    const naDecreet = computed(() => props.input.gerelateerdeWetgeving === StedenbouwkundigeOvertredingInputGerelateerdeWetgeving.NA_OMGEVINGSVERGUNNINGENDECREET)

    const addMisdrijf = () => {
      const copy = procedureItems.value.slice()
      copy.push({ type: ProcedureType.Misdrijf, procedure: {} as Misdrijf })
      procedureItems.value = copy
    }
    const addInbreuk = () => {
      const copy = procedureItems.value.slice()
      copy.push({ type: ProcedureType.Inbreuk, procedure: { verslagVanVaststelling: { } } as Inbreuk })
      procedureItems.value = copy
    }

    const makeUnique = (index: number) => {
      return 'procedure-' + index.toString() + (Math.floor(Math.random() * (1000000 - 1 + 1) + 1)).toString()
    }

    const requestRemoveByIndex = (index: number) => {
      indexToRemove.value = index
    }

    const removeByIndex = (index: number) => {
      const copy = procedureItems.value.slice()
      copy.splice(index, 1)
      procedureItems.value = copy
      indexToRemove.value = null
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const cancelRemove = () => {
      indexToRemove.value = null
    }

    const handleProcedureUpdated = (procedure: { index: number, value: Misdrijf | Inbreuk }) => {
      const copy = procedureItems.value.slice()
      copy[procedure.index].procedure = procedure.value
      procedureItems.value = copy
    }

    watch(() => procedureItems.value, (newValue) => {
      if (!loaded.value) {
        loaded.value = true
        return
      }
      const input = ref(props.input)
      input.value.misdrijven = newValue.filter(f => f.type === ProcedureType.Misdrijf).map(p => p.procedure as Misdrijf)
      input.value.inbreuken = newValue.filter(f => f.type === ProcedureType.Inbreuk).map(p => p.procedure as Inbreuk)
      emit('input-updated', input.value)
    })

    onBeforeMount(() => {
      const items = [] as Procedure[]
      if (props.input.misdrijven != null) {
        items.push(...(props.input.misdrijven.map(misdrijf => { return { type: ProcedureType.Misdrijf, procedure: misdrijf } as Procedure })))
      }
      if (props.input.inbreuken != null) {
        items.push(...(props.input.inbreuken.map(inbreuk => { return { type: ProcedureType.Inbreuk, procedure: inbreuk } as Procedure })))
      }
      procedureItems.value = items
    })

    return {
      procedureItems,
      naDecreet,
      indexToRemove,
      addMisdrijf,
      addInbreuk,
      makeUnique,
      requestRemoveByIndex,
      removeByIndex,
      cancelRemove,
      handleProcedureUpdated
    }
  }
})
