import { defineComponent, PropType, ref, watch } from 'vue'
import useHandhavingsinstrument
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/composable/handhavingsinstrument.composable'
import { Stakingsbevel } from '@/infrastructure/bff-client/bff-client'
import { HandhavingsinstrumentType } from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/edit/procedure/procedure-edit'
import useInlichtingValidation
  from '@/components/vip-inlichting/new-version/composables/inlichting-validation.composable'
import useStedenbouwkundigeOvertredingLabels
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/composable/stedenbouwkundige-overtreding-labels.composable'

export default defineComponent({
  name: 'stakingsbevel-edit',
  props: {
    instrument: Object as PropType<Stakingsbevel>,
    procedureIndex: Number,
    instrumentIndex: Number,
    modDisabled: Boolean,
    validationErrors: Map<string, string>
  },
  setup (props, { emit }) {
    const { makeUnique } = useHandhavingsinstrument()
    const { options, getLabel } = useStedenbouwkundigeOvertredingLabels()
    const { hasError, getError } = useInlichtingValidation(props.validationErrors)

    const stakingsbevelEdit = ref(props.instrument as Stakingsbevel)

    watch(() => stakingsbevelEdit.value, (newValue) => {
      emit('instrument-changed', { index: props.instrumentIndex, type: HandhavingsinstrumentType.Stakingsbevel, value: newValue })
    })

    return {
      stakingsbevelEdit,
      options,
      getLabel,
      hasError,
      getError,
      makeUnique
    }
  }
})
