import { computed, defineComponent, PropType, ref } from 'vue'
import {
  DossierStatus, HabitatrichtlijngebiedInlichting,
  Inlichting,
  TypeInlichting
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'

export default defineComponent({
  name: 'Habitatrichtlijngebied',
  components: { VipInlichtingPreviewNew },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props) {
    const previewData = ref(props.inlichting as HabitatrichtlijngebiedInlichting)
    const inlichtingType = TypeInlichting.Habitatrichtlijngebied
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)

    return {
      previewData,
      inlichtingType,
      inlichtingTitle
    }
  }
})
