import { GuiInlichtingRubriek, TypeInlichting } from '@/infrastructure/bff-client/bff-client'
import { groupBy } from '@/infrastructure/helpers/inlichting-map'

export enum Hoedanigheid {
  READ_ONLY = 'READ_ONLY',
  ADAPT = 'ADAPT',
  FEED = 'FEED',
}

export interface InlichtingInfo {
  inlichtingType: TypeInlichting,
  inlichtingTypeApi: string,
  label: string,
  hoedanigheid: Hoedanigheid,
  rubriek: GuiInlichtingRubriek,
  mandatory?: boolean,
  group?: string,
  newTemplate?: boolean,
  inactive?: boolean
}

export const inlichtingen: InlichtingInfo[] = [
  { inlichtingType: TypeInlichting.Gewestplan, inlichtingTypeApi: 'GEWESTPLAN', label: 'Gewestplan', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, mandatory: true },
  { inlichtingType: TypeInlichting.AlgemeenPlanVanAanleg, inlichtingTypeApi: 'ALGEMEEN_PLAN_VAN_AANLEG', label: 'Algemeen plan van aanleg', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, mandatory: true },
  { inlichtingType: TypeInlichting.BijzonderPlanVanAanleg, inlichtingTypeApi: 'BIJZONDER_PLAN_VAN_AANLEG', label: 'Bijzonder plan van aanleg', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, mandatory: true },
  { inlichtingType: TypeInlichting.GewestelijkRuimtelijkUitvoeringsplan, inlichtingTypeApi: 'GEWESTELIJK_RUIMTELIJK_UITVOERINGSPLAN', label: 'Gewestelijk', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, mandatory: true, group: 'Ruimtelijk uitvoeringsplan' },
  { inlichtingType: TypeInlichting.ProvinciaalRuimtelijkUitvoeringsplan, inlichtingTypeApi: 'PROVINCIAAL_RUIMTELIJK_UITVOERINGSPLAN', label: 'Provinciaal', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, mandatory: true, group: 'Ruimtelijk uitvoeringsplan' },
  { inlichtingType: TypeInlichting.GemeentelijkRuimtelijkUitvoeringsplan, inlichtingTypeApi: 'GEMEENTELIJK_RUIMTELIJK_UITVOERINGSPLAN', label: 'Gemeentelijk', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, mandatory: true, group: 'Ruimtelijk uitvoeringsplan' },
  { inlichtingType: TypeInlichting.GewestelijkeVerordening, inlichtingTypeApi: 'GEWESTELIJKE_VERORDENING', label: 'Gewestelijk', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, mandatory: true, group: 'Verordening' },
  { inlichtingType: TypeInlichting.ProvincialeVerordening, inlichtingTypeApi: 'PROVINCIALE_VERORDENING', label: 'Provinciaal', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, mandatory: true, group: 'Verordening' },
  { inlichtingType: TypeInlichting.GemeentelijkeVerordening, inlichtingTypeApi: 'GEMEENTELIJKE_VERORDENING', label: 'Gemeentelijk', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, mandatory: true, group: 'Verordening' },
  { inlichtingType: TypeInlichting.GemeentelijkRooilijnplan, inlichtingTypeApi: 'GEMEENTELIJK_ROOILIJNPLAN', label: 'Gemeentelijk rooilijnplan', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, mandatory: true },
  { inlichtingType: TypeInlichting.GemeentelijkOnteigeningsplan, inlichtingTypeApi: 'GEMEENTELIJK_ONTEIGENINGSPLAN', label: 'Gemeentelijk onteigeningsplan', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, mandatory: true },
  { inlichtingType: TypeInlichting.RechtVanVoorkoop, inlichtingTypeApi: 'RECHT_VAN_VOORKOOP', label: 'Recht van voorkoop', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Overheidsplannen },
  { inlichtingType: TypeInlichting.RuilOfHerverkavelingsplan, inlichtingTypeApi: 'RUIL_OF_HERVERKAVELINGSPLAN', label: 'Ruil- of herverkavelingsplan', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen },
  { inlichtingType: TypeInlichting.ComplexProject, inlichtingTypeApi: 'COMPLEX_PROJECT', label: 'Complex project', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen },
  { inlichtingType: TypeInlichting.PlanbatenOfSchade, inlichtingTypeApi: 'PLANBATEN_OF_SCHADE', label: 'Planbaten- of schade', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen },
  { inlichtingType: TypeInlichting.Beleidsplan, inlichtingTypeApi: 'BELEIDSPLAN', label: 'Beleidsplan', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen },
  { inlichtingType: TypeInlichting.SoortWegWaarlangsPerceelGelegenIs, inlichtingTypeApi: 'SOORT_WEG_WAARLANGS_PERCEEL_GELEGEN_IS', label: 'Soort weg waarlangs het perceel is gelegen', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Overheidsplannen, inactive: true },
  { inlichtingType: TypeInlichting.SoortWegWaarlangsPerceelGelegenIsV2, inlichtingTypeApi: 'SOORT_WEG_WAARLANGS_PERCEEL_GELEGEN_IS', label: 'Soort weg waarlangs het perceel is gelegen', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen },
  { inlichtingType: TypeInlichting.VoorwaardenVanuitEenGemeentelijkeAkte, inlichtingTypeApi: 'VOORWAARDEN_VANUIT_EEN_GEMEENTELIJKE_AKTE', label: 'Voorwaarden vanuit een gemeentelijke akte', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen },
  { inlichtingType: TypeInlichting.WatergevoeligOpenruimtegebied, inlichtingTypeApi: 'WATERGEVOELIG_OPENRUIMTEGEBIED', label: 'Watergevoelig openruimtegebied', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, mandatory: true, newTemplate: true },
  { inlichtingType: TypeInlichting.HerzieningOfOpheffingVerouderdeInrichtingsvoorschriften, inlichtingTypeApi: 'HERZIENING_OF_OPHEFFING_VEROUDERDE_INRICHTINGSVOORSCHRIFTEN', label: 'Herziening of opheffing verouderde inrichtingsvoorschriften', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Overheidsplannen, mandatory: true, newTemplate: true },
  { inlichtingType: TypeInlichting.AsBuiltAttest, inlichtingTypeApi: 'AS_BUILT_ATTEST', label: 'As-built attest', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.Vergunningen },
  { inlichtingType: TypeInlichting.Milieuvergunning, inlichtingTypeApi: 'MILIEUVERGUNNING', label: 'Milieuvergunning', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.Vergunningen, mandatory: true },
  { inlichtingType: TypeInlichting.Omgevingsvergunning, inlichtingTypeApi: 'OMGEVINGSVERGUNNING', label: 'Omgevingsvergunning', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.Vergunningen, mandatory: true },
  { inlichtingType: TypeInlichting.PlanologischAttest, inlichtingTypeApi: 'PLANOLOGISCH_ATTEST', label: 'Planologisch attest', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.Vergunningen },
  { inlichtingType: TypeInlichting.Splitsing, inlichtingTypeApi: 'SPLITSING', label: 'Splitsing', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.Vergunningen },
  { inlichtingType: TypeInlichting.StedenbouwkundigAttest, inlichtingTypeApi: 'STEDENBOUWKUNDIG_ATTEST', label: 'Stedenbouwkundig attest', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.Vergunningen, mandatory: true },
  { inlichtingType: TypeInlichting.StedenbouwkundigeMelding, inlichtingTypeApi: 'STEDENBOUWKUNDIGE_MELDING', label: 'Stedenbouwkundige melding', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.Vergunningen, mandatory: true },
  { inlichtingType: TypeInlichting.StedenbouwkundigeOvertreding, inlichtingTypeApi: 'STEDENBOUWKUNDIGE_OVERTREDING', label: 'Stedenbouwkundige overtreding', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.Vergunningen, mandatory: true, newTemplate: true },
  { inlichtingType: TypeInlichting.StedenbouwkundigeVergunning, inlichtingTypeApi: 'STEDENBOUWKUNDIGE_VERGUNNING', label: 'Stedenbouwkundige vergunning', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.Vergunningen, mandatory: true },
  { inlichtingType: TypeInlichting.Verkavelingsvergunning, inlichtingTypeApi: 'VERKAVELINGSVERGUNNING', label: 'Verkavelingsvergunning', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.Vergunningen, mandatory: true },
  { inlichtingType: TypeInlichting.VergundGeachteGebouwen, inlichtingTypeApi: 'VERGUND_GEACHTE_GEBOUWEN', label: 'Vergund geachte gebouwen', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.Vergunningen },
  { inlichtingType: TypeInlichting.Vergunningstoestand, inlichtingTypeApi: 'VERGUNNINGSTOESTAND', label: 'Vergunningstoestand', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.Vergunningen },
  { inlichtingType: TypeInlichting.BeschermdDuingebied, inlichtingTypeApi: 'BESCHERMD_DUINGEBIED', label: 'Beschermd duingebied', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Natuur, newTemplate: true },
  { inlichtingType: TypeInlichting.BeschermdWaterwinningsgebied, inlichtingTypeApi: 'BESCHERMD_WATERWINNINGSGEBIED', label: 'Beschermd waterwinningsgebied', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Natuur, newTemplate: true },
  { inlichtingType: TypeInlichting.Habitatrichtlijngebied, inlichtingTypeApi: 'HABITATRICHTLIJNGEBIED', label: 'Habitatrichtlijngebied', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Natuur, newTemplate: true },
  { inlichtingType: TypeInlichting.Kapmachtiging, inlichtingTypeApi: 'KAPMACHTIGING', label: 'Gemeentelijke kapmachtiging', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.Natuur, newTemplate: true },
  { inlichtingType: TypeInlichting.Natuurbeheerplan, inlichtingTypeApi: 'NATUURBEHEERPLAN', label: 'Beheerplan', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Natuur, newTemplate: true },
  { inlichtingType: TypeInlichting.RisicozoneVoorOverstromingen, inlichtingTypeApi: 'RISICOZONE_VOOR_OVERSTROMINGEN', label: 'Risicozone voor overstromingen', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Natuur, mandatory: true, newTemplate: true },
  { inlichtingType: TypeInlichting.Signaalgebied, inlichtingTypeApi: 'SIGNAALGEBIED', label: 'Signaalgebied', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Natuur, newTemplate: true },
  { inlichtingType: TypeInlichting.OverstromingsgebiedEnOeverzone, inlichtingTypeApi: 'OVERSTROMINGSGEBIED_EN_OEVERZONE', label: 'Overstromingsgebied of oeverzone', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Natuur, mandatory: true, newTemplate: true },
  { inlichtingType: TypeInlichting.Overstromingsgevoeligheid, inlichtingTypeApi: 'OVERSTROMINGSGEVOELIGHEID', label: 'Overstromingsgevoeligheid', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Natuur, inactive: true },
  { inlichtingType: TypeInlichting.OverstromingsgevoeligheidV2, inlichtingTypeApi: 'OVERSTROMINGSGEVOELIGHEID', label: 'Overstromingsgevoeligheid', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Natuur, mandatory: true, newTemplate: true },
  { inlichtingType: TypeInlichting.VenEnIvonGebied, inlichtingTypeApi: 'VEN_EN_IVON_GEBIED', label: 'VEN- & IVON-gebied', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Natuur, newTemplate: true },
  { inlichtingType: TypeInlichting.Vogelrichtlijngebied, inlichtingTypeApi: 'VOGELRICHTLIJNGEBIED', label: 'Vogelrichtlijngebied', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Natuur, newTemplate: true },
  { inlichtingType: TypeInlichting.Natuurinrichtingsproject, inlichtingTypeApi: 'NATUURINRICHTINGSPROJECT', label: 'Natuurinrichtingsproject', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Natuur, newTemplate: true },
  { inlichtingType: TypeInlichting.Grondverschuivingsgevoeligheid, inlichtingTypeApi: 'GRONDVERSCHUIVINGSGEVOELIGHEID', label: 'Grondverschuivingsgevoeligheid', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Natuur, newTemplate: true },
  { inlichtingType: TypeInlichting.WettelijkeErfdienstbaarheidVanOpenbaarNut, inlichtingTypeApi: 'WETTELIJKE_ERFDIENSTBAARHEID_VAN_OPENBAAR_NUT', label: 'Wettelijke erfdienstbaarheid van openbaar nut', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.Erfdienstbaarheden },
  { inlichtingType: TypeInlichting.AankoopOfGebruikGemeentegrond, inlichtingTypeApi: 'AANKOOP_OF_GEBRUIK_GEMEENTEGROND', label: 'Aankoop of gebruik gemeentegrond', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.GrondEnPandenBeleid, newTemplate: true },
  { inlichtingType: TypeInlichting.Bedrijventerrein, inlichtingTypeApi: 'BEDRIJVENTERREIN', label: 'Bedrijventerrein', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.GrondEnPandenBeleid, newTemplate: true },
  { inlichtingType: TypeInlichting.BesluitBurgemeesterInzakeOpenbareVeiligheid, inlichtingTypeApi: 'BESLUIT_BURGEMEESTER_INZAKE_OPENBARE_VEILIGHEID', label: 'Besluit van de burgemeester inzake openbare veiligheid', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.GrondEnPandenBeleid, newTemplate: true },
  { inlichtingType: TypeInlichting.Brownfield, inlichtingTypeApi: 'BROWNFIELD', label: 'Brownfieldconvenant', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.GrondEnPandenBeleid, newTemplate: true },
  { inlichtingType: TypeInlichting.Conformiteitsattest, inlichtingTypeApi: 'CONFORMITEITSATTEST', label: 'Conformiteitsattest', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.GrondEnPandenBeleid, mandatory: true, newTemplate: true },
  { inlichtingType: TypeInlichting.Herstelvordering, inlichtingTypeApi: 'HERSTELVORDERING', label: 'Herstelvordering', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.GrondEnPandenBeleid, mandatory: true },
  { inlichtingType: TypeInlichting.LeegstaandeEnVerwaarloosdeGebouwen, inlichtingTypeApi: 'LEEGSTAANDE_EN_VERWAARLOOSDE_GEBOUWEN', label: 'Leegstaand en/of verwaarloosd gebouw', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.GrondEnPandenBeleid, mandatory: true, newTemplate: true },
  { inlichtingType: TypeInlichting.LeegstaandeEnVerwaarloosdeBedrijfsruimten, inlichtingTypeApi: 'LEEGSTAANDE_EN_VERWAARLOOSDE_BEDRIJFSRUIMTEN', label: 'Leegstaande en/of verwaarloosde bedrijfsruimte', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.GrondEnPandenBeleid, inactive: true },
  { inlichtingType: TypeInlichting.LeegstaandeEnVerwaarloosdeBedrijfsruimtenV2, inlichtingTypeApi: 'LEEGSTAANDE_EN_VERWAARLOOSDE_BEDRIJFSRUIMTEN', label: 'Leegstaande en/of verwaarloosde bedrijfsruimte', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.GrondEnPandenBeleid, newTemplate: true },
  { inlichtingType: TypeInlichting.OnbebouwdePercelen, inlichtingTypeApi: 'ONBEBOUWDE_PERCELEN', label: 'Onbebouwd perceel', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.GrondEnPandenBeleid, newTemplate: true },
  { inlichtingType: TypeInlichting.OnbewoonbareEnOngeschikteWoning, inlichtingTypeApi: 'ONBEWOONBARE_EN_ONGESCHIKTE_WONING', label: 'Onbewoonbare en/of ongeschikte woning', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.GrondEnPandenBeleid, mandatory: true, newTemplate: true },
  { inlichtingType: TypeInlichting.VerbodTotPermanentWonen, inlichtingTypeApi: 'VERBOD_TOT_PERMANENT_WONEN', label: 'Verbod tot permanent wonen', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.GrondEnPandenBeleid, newTemplate: true },
  { inlichtingType: TypeInlichting.Risicogrond, inlichtingTypeApi: 'RISICOGROND', label: 'Risicogrond', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Milieu, mandatory: false, newTemplate: true },
  { inlichtingType: TypeInlichting.RisicogrondV2, inlichtingTypeApi: 'RISICOGROND', label: 'Risicogrond', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.Milieu, mandatory: true },
  { inlichtingType: TypeInlichting.Zoneringsplan, inlichtingTypeApi: 'ZONERINGSPLAN', label: 'Zoneringsplan', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.Milieu, mandatory: false, newTemplate: true },
  { inlichtingType: TypeInlichting.GemeentelijkeHeffing, inlichtingTypeApi: 'GEMEENTELIJKE_HEFFING', label: 'Gemeentelijke heffing', hoedanigheid: Hoedanigheid.FEED, rubriek: GuiInlichtingRubriek.GemeentelijkeHeffingen },
  { inlichtingType: TypeInlichting.OnroerendErfgoed, inlichtingTypeApi: 'ONROEREND_ERFGOED', label: 'Onroerend erfgoed', hoedanigheid: Hoedanigheid.READ_ONLY, rubriek: GuiInlichtingRubriek.OnroerendErfgoed, mandatory: true, newTemplate: true },
  { inlichtingType: TypeInlichting.LokaalOnroerendErfgoed, inlichtingTypeApi: 'LOKAAL_ONROEREND_ERFGOED', label: 'Lokaal onroerend erfgoed', hoedanigheid: Hoedanigheid.ADAPT, rubriek: GuiInlichtingRubriek.OnroerendErfgoed, newTemplate: true },
]

// eslint-disable-next-line func-call-spacing
export const inlichtingTypeCanAddByRubriek = (): Map<GuiInlichtingRubriek, InlichtingInfo[]> => groupBy(inlichtingen.filter(inlichting => inlichting.hoedanigheid !== Hoedanigheid.READ_ONLY), 'rubriek')

export const allDeletableInlichtingenByRubriek = (rubriek: GuiInlichtingRubriek): InlichtingInfo[] => Array.from(inlichtingTypeCanAddByRubriek().get(rubriek).values())

export const inlichtingenWithNewTemplateByRubriek = (rubriek: GuiInlichtingRubriek): Map<string, InlichtingInfo[]> =>
  groupBy(inlichtingen.filter(inlichting => inlichting.rubriek === rubriek && inlichting.newTemplate && !inlichting.inactive), 'inlichtingTypeApi')

export const inlichtingenWithNewTemplateByInlichtingType = (): Map<TypeInlichting, InlichtingInfo[]> => groupBy(inlichtingen.filter(inlichting => inlichting.newTemplate && !inlichting.inactive), 'inlichtingType')

export enum InlichtingType {
  GEWESTPLAN = 'GEWESTPLAN',
  ALGEMEEN_PLAN_VAN_AANLEG = 'ALGEMEEN_PLAN_VAN_AANLEG',
  BIJZONDER_PLAN_VAN_AANLEG = 'BIJZONDER_PLAN_VAN_AANLEG',
  GEWESTELIJK_RUIMTELIJK_UITVOERINGSPLAN = 'GEWESTELIJK_RUIMTELIJK_UITVOERINGSPLAN',
  PROVINCIAAL_RUIMTELIJK_UITVOERINGSPLAN = 'PROVINCIAAL_RUIMTELIJK_UITVOERINGSPLAN',
  GEMEENTELIJK_RUIMTELIJK_UITVOERINGSPLAN = 'GEMEENTELIJK_RUIMTELIJK_UITVOERINGSPLAN',
  GEWESTELIJKE_VERORDENING = 'GEWESTELIJKE_VERORDENING',
  PROVINCIALE_VERORDENING = 'PROVINCIALE_VERORDENING',
  GEMEENTELIJKE_VERORDENING = 'GEMEENTELIJKE_VERORDENING',
  GEMEENTELIJK_ROOILIJNPLAN = 'GEMEENTELIJK_ROOILIJNPLAN',
  GEMEENTELIJK_ONTEIGENINGSPLAN = 'GEMEENTELIJK_ONTEIGENINGSPLAN',
  RECHT_VAN_VOORKOOP = 'RECHT_VAN_VOORKOOP',
  RUIL_OF_HERVERKAVELINGSPLAN = 'RUIL_OF_HERVERKAVELINGSPLAN',
  COMPLEX_PROJECT = 'COMPLEX_PROJECT',
  PLANBATEN_OF_SCHADE = 'PLANBATEN_OF_SCHADE',
  VOORWAARDEN_VANUIT_EEN_GEMEENTELIJKE_AKTE = 'VOORWAARDEN_VANUIT_EEN_GEMEENTELIJKE_AKTE',
  BELEIDSPLAN = 'BELEIDSPLAN',
  SOORT_WEG_WAARLANGS_PERCEEL_GELEGEN_IS = 'SOORT_WEG_WAARLANGS_PERCEEL_GELEGEN_IS',
  WATERGEVOELIG_OPENRUIMTEGEBIED = 'WATERGEVOELIG_OPENRUIMTEGEBIED',
  STEDENBOUWKUNDIGE_VERGUNNING = 'STEDENBOUWKUNDIGE_VERGUNNING',
  STEDENBOUWKUNDIGE_MELDING = 'STEDENBOUWKUNDIGE_MELDING',
  VERKAVELINGSVERGUNNING = 'VERKAVELINGSVERGUNNING',
  VERGUND_GEACHTE_GEBOUWEN = 'VERGUND_GEACHTE_GEBOUWEN',
  MILIEUVERGUNNING = 'MILIEUVERGUNNING',
  OMGEVINGSVERGUNNING = 'OMGEVINGSVERGUNNING',
  STEDENBOUWKUNDIGE_OVERTREDING = 'STEDENBOUWKUNDIGE_OVERTREDING',
  STEDENBOUWKUNDIG_ATTEST = 'STEDENBOUWKUNDIG_ATTEST',
  AS_BUILT_ATTEST = 'AS_BUILT_ATTEST',
  PLANOLOGISCH_ATTEST = 'PLANOLOGISCH_ATTEST',
  SPLITSING = 'SPLITSING',
  VERGUNNINGSTOESTAND = 'VERGUNNINGSTOESTAND',
  ZONERINGSPLAN = 'ZONERINGSPLAN',
  RISICOGROND = 'RISICOGROND',
  VOGELRICHTLIJNGEBIED = 'VOGELRICHTLIJNGEBIED',
  HABITATRICHTLIJNGEBIED = 'HABITATRICHTLIJNGEBIED',
  VEN_EN_IVON_GEBIED = 'VEN_EN_IVON_GEBIED',
  BESCHERMD_DUINGEBIED = 'BESCHERMD_DUINGEBIED',
  BESCHERMD_WATERWINNINGSGEBIED = 'BESCHERMD_WATERWINNINGSGEBIED',
  OVERSTROMINGSGEVOELIGHEID = 'OVERSTROMINGSGEVOELIGHEID',
  OVERSTROMINGSGEBIED_EN_OEVERZONE = 'OVERSTROMINGSGEBIED_EN_OEVERZONE',
  RISICOZONE_VOOR_OVERSTROMINGEN = 'RISICOZONE_VOOR_OVERSTROMINGEN',
  SIGNAALGEBIED = 'SIGNAALGEBIED',
  NATUURBEHEERPLAN = 'NATUURBEHEERPLAN',
  KAPMACHTIGING = 'KAPMACHTIGING',
  NATUURINRICHTINGSPROJECT = 'NATUURINRICHTINGSPROJECT',
  GRONDVERSCHUIVINGSGEVOELIGHEID = 'GRONDVERSCHUIVINGSGEVOELIGHEID',
  ONBEWOONBARE_EN_ONGESCHIKTE_WONING = 'ONBEWOONBARE_EN_ONGESCHIKTE_WONING',
  HERSTELVORDERING = 'HERSTELVORDERING',
  CONFORMITEITSATTEST = 'CONFORMITEITSATTEST',
  LEEGSTAANDE_EN_VERWAARLOOSDE_GEBOUWEN = 'LEEGSTAANDE_EN_VERWAARLOOSDE_GEBOUWEN',
  LEEGSTAANDE_EN_VERWAARLOOSDE_BEDRIJFSRUIMTEN = 'LEEGSTAANDE_EN_VERWAARLOOSDE_BEDRIJFSRUIMTEN',
  BROWNFIELD = 'BROWNFIELD',
  BEDRIJVENTERREIN = 'BEDRIJVENTERREIN',
  ONBEBOUWDE_PERCELEN = 'ONBEBOUWDE_PERCELEN',
  AANKOOP_OF_GEBRUIK_GEMEENTEGROND = 'AANKOOP_OF_GEBRUIK_GEMEENTEGROND',
  BESLUIT_BURGEMEESTER_INZAKE_OPENBARE_VEILIGHEID = 'BESLUIT_BURGEMEESTER_INZAKE_OPENBARE_VEILIGHEID',
  VERBOD_TOT_PERMANENT_WONEN = 'VERBOD_TOT_PERMANENT_WONEN',
  ONROEREND_ERFGOED = 'ONROEREND_ERFGOED',
  LOKAAL_ONROEREND_ERFGOED = 'LOKAAL_ONROEREND_ERFGOED',
  WETTELIJKE_ERFDIENSTBAARHEID_VAN_OPENBAAR_NUT = 'WETTELIJKE_ERFDIENSTBAARHEID_VAN_OPENBAAR_NUT',
  GEMEENTELIJKE_HEFFING = 'GEMEENTELIJKE_HEFFING',
  HERZIENING_OF_OPHEFFING_VEROUDERDE_INRICHTINGSVOORSCHRIFTEN = 'HERZIENING_OF_OPHEFFING_VEROUDERDE_INRICHTINGSVOORSCHRIFTEN',
}
