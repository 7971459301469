import { ref } from 'vue'
import { generateKey } from '@/infrastructure/helpers/general'

export default function useHandhavingsinstrument () {
  const existingIds = ref<Map<string, string>>(new Map([]))
  const makeUnique = (element: string) => {
    const elementId = `${element}-${generateKey()}`
    if (existingIds.value.has(element)) {
      return existingIds.value.get(element)
    }
    existingIds.value.set(element, elementId)
    return elementId
  }
  return {
    makeUnique
  }
}
