import { computed, defineComponent, watch } from 'vue'
import { AlertMethod, AlertType, IAlert, useAlertStore } from '@/infrastructure/stores/alert-store'
import { useRoute } from 'vue-router/composables'

export default defineComponent({
  name: 'vip-alert',
  setup () {
    const alertStore = useAlertStore()
    const route = useRoute()

    const alerts = computed(() => alertStore.alerts(AlertMethod.Page))
    const toasters = computed(() => alertStore.alerts(AlertMethod.Toast))
    const documentationClosed = computed(() => alertStore.documentationIsClosed)
    const types = computed(() => AlertType)
    const alertsAndToasters = computed(() => alertStore.alertsAndToasters)

    const removeAlert = async (id: string) => {
      alertStore.removeAlerts([id])
    }

    const closeDocumentation = () => {
      alertStore.closeDocumentation()
    }

    watch(
      () => route,
      (newRoute, oldRoute) => {
        if (oldRoute?.name !== newRoute?.name) {
          const alerts = alertStore.alerts(null)
          const alertsToRemove = alerts.filter((alert: IAlert) =>
            alert.routes.some(ar => ar.name === newRoute.name || ar.path === newRoute.path)
          )
          if (alertsToRemove.length) {
            alertStore.removeAlerts(alertsToRemove.map((alert: IAlert) => alert.id))
          }
        }
      },
      { immediate: true }
    )

    return {
      alerts,
      toasters,
      types,
      alertsAndToasters,
      documentationClosed,
      removeAlert,
      closeDocumentation
    }
  }
})
