import { computed, defineComponent, PropType, ref, watch } from 'vue'
import {
  Aanmaning, BestuurlijkeHerstelmaatregel,
  Inbreuk, InbreukBestuurlijkeSanctie,
  MinnelijkeSchikking,
  Misdrijf,
  ProcesVerbaal,
  Stakingsbevel
} from '@/infrastructure/bff-client/bff-client'
import { generateKey } from '@/infrastructure/helpers/general'
import { ProcedureType } from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/edit/procedure/procedures-edit'
import EventBus from '@/infrastructure/events/event-bus'
import useInlichtingValidation
  from '@/components/vip-inlichting/new-version/composables/inlichting-validation.composable'

export enum HandhavingsinstrumentType {
  Aanmaning = 'aanmaning',
  ProcesVerbaal = 'proces-verbaal',
  Stakingsbevel = 'stakingsbevel',
  MinnelijkeSchikking = 'minnelijke-schikking',
  BestuurlijkeHerstelmaatregel = 'bestuurlijke-herstelmaatregel',
  BestuurlijkeSanctie = 'bestuurlijke-sanctie'
}

export interface Handhavingsinstrument {
  type: HandhavingsinstrumentType,
  instrument?: Aanmaning | ProcesVerbaal | Stakingsbevel | MinnelijkeSchikking | BestuurlijkeHerstelmaatregel | InbreukBestuurlijkeSanctie,
  instrumentIndex?: number
}

export default defineComponent({
  name: 'procedure-edit',
  components: {
    MinnelijkeSchikkingEdit: () => import('@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/edit/procedure/handhavingsinstrumenten/minnelijkeschikking-edit.vue'),
    BestuurlijkeSanctieEdit: () => import('@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/edit/procedure/handhavingsinstrumenten/bestuurlijke-sanctie-edit.vue'),
    AanmaningEdit: () => import('@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/edit/procedure/handhavingsinstrumenten/aanmaning-edit.vue'),
    ProcesVerbaalEdit: () => import('@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/edit/procedure/handhavingsinstrumenten/proces-verbaal-edit.vue'),
    StakingsbevelEdit: () => import('@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/edit/procedure/handhavingsinstrumenten/stakingsbevel-edit.vue'),
    BestuurlijkeHerstelmaatregelEdit: () => import('@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/edit/procedure/handhavingsinstrumenten/bestuurlijke-herstelmaatregel-edit.vue')
  },
  props: {
    procedure: Object as PropType<Misdrijf | Inbreuk>,
    procedureType: String as PropType<ProcedureType>,
    procedureIndex: Number,
    modDisabled: Boolean,
    validationErrors: Map<string, string>
  },
  setup (props, { emit }) {
    const loaded = ref(false)
    const procedureEdit = ref(props.procedure as any)
    const uniqueKey = ref(generateKey())
    const procedureRefs = ref([])
    const inbreuk = computed(() => props.procedure as Inbreuk)
    const handhavingsinstrumentToevoegenActief = ref(false)
    const activeInstrument = ref('')
    const handhavingsInstrumentToRemove = ref<Handhavingsinstrument>(null)

    const { hasError, getError } = useInlichtingValidation(props.validationErrors)

    const isInbreuk = computed(() => props.procedureType === ProcedureType.Inbreuk)
    const procedureTitle = computed(() => isInbreuk.value ? 'Inbreuk' : 'Misdrijf')

    const handhavingsinstrumentenOptions = computed(() => {
      const instrumenten = [['Aanmaning', 'aanmaning']]
      if (!isInbreuk.value) {
        instrumenten.push(['Proces-verbaal', 'proces-verbaal'])
      }
      instrumenten.push(['Stakingsbevel', 'stakingsbevel'])
      instrumenten.push(['Minnelijke schikking', 'minnelijke-schikking'])
      instrumenten.push(['Bestuurlijke herstelmaatregel', 'bestuurlijke-herstelmaatregel'])
      if (isInbreuk.value) {
        instrumenten.push(['Bestuurlijke sanctie', 'bestuurlijke-sanctie'])
      }
      return instrumenten
    })

    const uitgeslotenProcedures = computed(() => {
      const uitgeslotenProcedures = []
      if ((procedureEdit.value as any).minnelijkeSchikking) {
        uitgeslotenProcedures.push('minnelijke-schikking')
      }
      if ((procedureEdit.value as any).stakingsbevel) {
        uitgeslotenProcedures.push('stakingsbevel')
      }
      return uitgeslotenProcedures
    })

    const flattenInstruments = (): Handhavingsinstrument[] => {
      const handhavingsinstrumenten = []
      procedureEdit.value.aanmaningen?.forEach((aanmaning, index) => {
        handhavingsinstrumenten.push({ type: HandhavingsinstrumentType.Aanmaning, instrument: aanmaning, instrumentIndex: index })
      })
      procedureEdit.value.processenVerbaal?.forEach((pv, index) => {
        handhavingsinstrumenten.push({ type: HandhavingsinstrumentType.ProcesVerbaal, instrument: pv, instrumentIndex: index })
      })
      if (procedureEdit.value.stakingsbevel != null) {
        handhavingsinstrumenten.push({ type: HandhavingsinstrumentType.Stakingsbevel, instrument: procedureEdit.value.stakingsbevel })
      }
      if (procedureEdit.value.minnelijkeSchikking != null) {
        handhavingsinstrumenten.push({ type: HandhavingsinstrumentType.MinnelijkeSchikking, instrument: procedureEdit.value.minnelijkeSchikking })
      }
      procedureEdit.value.bestuurlijkeHerstelmaatregelen?.forEach((maatregel, index) => {
        handhavingsinstrumenten.push({ type: HandhavingsinstrumentType.BestuurlijkeHerstelmaatregel, instrument: maatregel, instrumentIndex: index })
      })
      if (procedureEdit.value.bestuurlijkeSanctie != null) {
        handhavingsinstrumenten.push({ type: HandhavingsinstrumentType.BestuurlijkeSanctie, instrument: procedureEdit.value.bestuurlijkeSanctie })
      }
      return handhavingsinstrumenten
    }

    const makeUnique = (element: string) => `${element}-${uniqueKey.value}`

    const handhavingsinstrumentToevoegen = () => {
      handhavingsinstrumentToevoegenActief.value = true
    }

    const handhavingsinstrumentSelectieToevoegen = () => {
      switch (activeInstrument.value) {
        case 'aanmaning':
          if (procedureEdit.value.aanmaningen == null) { procedureEdit.value.aanmaningen = [] }
          procedureEdit.value.aanmaningen.push({} as Aanmaning)
          break
        case 'proces-verbaal':
          if (procedureEdit.value.processenVerbaal == null) { procedureEdit.value.processenVerbaal = [] }
          procedureEdit.value.processenVerbaal.push({} as ProcesVerbaal)
          break
        case 'stakingsbevel':
          procedureEdit.value.stakingsbevel = {} as Stakingsbevel
          break
        case 'minnelijke-schikking':
          procedureEdit.value.minnelijkeSchikking = {} as MinnelijkeSchikking
          break
        case 'bestuurlijke-herstelmaatregel':
          if (procedureEdit.value.bestuurlijkeHerstelmaatregelen == null) { procedureEdit.value.bestuurlijkeHerstelmaatregelen = [] }
          procedureEdit.value.bestuurlijkeHerstelmaatregelen.push({} as BestuurlijkeHerstelmaatregel)
          break
        case 'bestuurlijke-sanctie':
          procedureEdit.value.bestuurlijkeSanctie = InbreukBestuurlijkeSanctie.GEEN_BESTUURLIJKE_SANCTIE
          break
      }
      flattenInstruments()
      handhavingsinstrumentToevoegenActief.value = false
    }

    const handhavingsinstrumentToevoegenAnnuleren = () => {
      handhavingsinstrumentToevoegenActief.value = false
    }

    const requestRemoveHandhavingsinstrument = (instrument: Handhavingsinstrument) => {
      handhavingsInstrumentToRemove.value = instrument
    }

    const handleHandhavingsinstrumentChange = (instrumentToChange: { index: number, type: HandhavingsinstrumentType, value: any}) => {
      const procedureCopy = { ...procedureEdit.value }
      switch (instrumentToChange.type) {
        case HandhavingsinstrumentType.Aanmaning:
          procedureCopy.aanmaningen[instrumentToChange.index] = instrumentToChange.value
          break
        case HandhavingsinstrumentType.ProcesVerbaal:
          procedureCopy.processenVerbaal[instrumentToChange.index] = instrumentToChange.value
          break
        case HandhavingsinstrumentType.Stakingsbevel:
          procedureCopy.stakingsbevel = instrumentToChange.value
          break
        case HandhavingsinstrumentType.MinnelijkeSchikking:
          procedureCopy.minnelijkeSchikking = instrumentToChange.value
          break
        case HandhavingsinstrumentType.BestuurlijkeHerstelmaatregel: {
          procedureCopy.bestuurlijkeHerstelmaatregelen[instrumentToChange.index] = instrumentToChange.value
          break
        }
        case HandhavingsinstrumentType.BestuurlijkeSanctie: {
          procedureCopy.bestuurlijkeSanctie = instrumentToChange.value
          break
        }
      }
      procedureEdit.value = procedureCopy
    }

    const removeHandhavingsinstrument = () => {
      const procedureCopy = { ...procedureEdit.value }
      switch (handhavingsInstrumentToRemove.value.type) {
        case HandhavingsinstrumentType.Aanmaning:
          procedureCopy.aanmaningen.splice(handhavingsInstrumentToRemove.value.instrumentIndex, 1)
          break
        case HandhavingsinstrumentType.ProcesVerbaal:
          procedureCopy.processenVerbaal.splice(handhavingsInstrumentToRemove.value.instrumentIndex, 1)
          break
        case HandhavingsinstrumentType.Stakingsbevel:
          procedureCopy.stakingsbevel = null
          break
        case HandhavingsinstrumentType.MinnelijkeSchikking:
          procedureCopy.minnelijkeSchikking = null
          break
        case HandhavingsinstrumentType.BestuurlijkeHerstelmaatregel: {
          procedureCopy.bestuurlijkeHerstelmaatregelen.splice(handhavingsInstrumentToRemove.value.instrumentIndex, 1)
          break
        }
        case HandhavingsinstrumentType.BestuurlijkeSanctie: {
          procedureCopy.bestuurlijkeSanctie = null
          break
        }
      }
      procedureEdit.value = procedureCopy
      handhavingsInstrumentToRemove.value = null
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const cancelRemove = () => {
      handhavingsInstrumentToRemove.value = null
    }

    watch(() => procedureEdit.value, (newValue) => {
      if (!loaded.value) {
        loaded.value = true
      }
      emit('procedure-updated', { index: props.procedureIndex, value: newValue })
    })

    return {
      procedureEdit,
      procedureRefs,
      inbreuk,
      procedureTitle,
      isInbreuk,
      handhavingsinstrumentenOptions,
      handhavingsinstrumentToevoegenActief,
      uitgeslotenProcedures,
      activeInstrument,
      handhavingsInstrumentToRemove,
      hasError,
      getError,
      makeUnique,
      flattenInstruments,
      handhavingsinstrumentToevoegen,
      handhavingsinstrumentSelectieToevoegen,
      handhavingsinstrumentToevoegenAnnuleren,
      requestRemoveHandhavingsinstrument,
      handleHandhavingsinstrumentChange,
      removeHandhavingsinstrument,
      cancelRemove
    }
  }
})
