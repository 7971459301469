import {
  InlichtingDatePeriodAttribuut,
  CodelijstElement,
  InlichtingResponse,
  InlichtingAttribuut,
  Kardinaliteit,
  DossierDetail,
  DossierStatus
} from '@/infrastructure/bff-client/bff-client'
import { BffClientFactory } from '@/infrastructure/bff-client/bff-client-handler'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { VipInlichtingAccordion } from '@/components/vip-inlichting/components/vip-inlichting-accordion/vip-inlichting-accordion'
import { catchBffError, generateKey, makeUniqueKey } from '@/infrastructure/helpers/general'
import { useDossierStore } from '@/infrastructure/stores/dossier-store'
import { useUserStore } from '@/infrastructure/stores/user-store'
import Vue from 'vue'
import { AlertIcons, AlertMethod, AlertType, useAlertStore } from '@/infrastructure/stores/alert-store'
import { VipInlichtingMapper } from './vip-inlichting-mapper'
import { Aangevraagd, Toegekend } from '@/infrastructure/constants/dashboard-tabs'
import { VipInlichtingBijlagenService } from './vip-inlichting-bijlagen-service'
import EventBus from '@/infrastructure/events/event-bus'
import { mapStores } from 'pinia'

export interface InlichtingComponentData extends InlichtingResponse {
  template: string;
  previewTemplate: string;
  attributesTemplate: string;
}

const _vipInlichtingMapper = new VipInlichtingMapper()

export default Vue.extend({
  name: 'vip-inlichting',
  props: {
    inlichting: Object as ()=> InlichtingComponentData,
    inlichtingType: Number,
    dossier: Object as ()=> DossierDetail,
    bijlagen: Object as ()=> File[]
  },
  data () {
    return {
      componentKey: `inlichting-${generateKey()}`,
      alertId: 'inlichting-validation',
      inlichtingClone: null,
      isSaving: false,
      previewComponentRef: '',
      validationFailed: false,
      bijlagenToUpload: this.bijlagen
    }
  },
  computed: {
    ...mapStores(useAlertStore, useUserStore, useDossierStore),
    userCanEdit (): boolean {
      return this.userStore.userCanEditDossier
    },
    component (): string {
      return `vip-inlichting-template-${this.inlichting.template}`
    },
    showHeader (): boolean {
      return (this.userStore.userCanEditDossier || this.userStore.userIsAdmin) && this.dossierEditable
    },
    canEdit (): boolean {
      return this.userStore.userCanEditDossier
    },
    dossierEditable (): boolean {
      return !this.dossierStore.dossierIsReadOnly && this.$route?.params?.dossierType !== Aangevraagd
    },
    previewComponent (): string {
      return `vip-inlichting-preview-template-${this.inlichting.previewTemplate}`
    },
    attributesComponent (): string {
      return `vip-inlichting-attributes-template-${this.inlichting.attributesTemplate}`
    },
    title (): string {
      return (this.inlichting as InlichtingResponse).label
    },
    isAltHeader (): boolean {
      return (this.inlichting as InlichtingComponentData).vanToepassing === null
    },
    showAttributes (): boolean {
      return this.inlichting.vanToepassing && this.inlichting.attributen && this.dossierEditable && (this.canEdit || this.userStore.userIsAdmin)
    },
    showValidationWarning (): boolean {
      return this.userStore.userActiveTab === Toegekend &&
          this.userStore.userCanEditDossier &&
          this.inlichting.inlichtingTypeCode.kardinaliteit === Kardinaliteit.OneOrMore &&
          this.dossier.dossierStatus !== DossierStatus.Gevalideerd
    }
  },
  methods: {
    reloadComponent () {
      this.componentKey = `inlichting-${generateKey()}`
    },
    bijlagenToUploadChanged (bijlagenToUploadData: File[]) {
      this.bijlagenToUpload = bijlagenToUploadData
    },
    async save () {
      await this.saveInlichting(this.inlichting.inlichtingId, (this.inlichting as InlichtingComponentData).inlichtingTypeCode.value, this.inlichting.attributen, this.inlichting.vanToepassing)
    },
    async saveInlichting (inlichtingId: string, inlichtingTypeCode: string,
      inlichtingAttributen: InlichtingAttribuut[], vanToepassing: boolean): Promise<string> {
      this.validationFailed = false
      if (this.validateInlichting(inlichtingAttributen, vanToepassing)) {
        const dossierId = this.dossierStore.dossierModuleGetDossierId
        const inlichtingAanvulling = _vipInlichtingMapper.mapToInlichtingData(dossierId, vanToepassing, inlichtingTypeCode, inlichtingAttributen)
        this.isSaving = true
        inlichtingAanvulling.dossierId = dossierId
        let errorMessage = ''
        try {
          errorMessage = 'bewaren-inlichting-error'

          if (inlichtingId) {
            inlichtingAanvulling.inlichtingId = inlichtingId
            await BffClientFactory().dossierInlichtingen_Put(inlichtingAanvulling)
          } else {
            errorMessage = 'toevoegen-inlichting-error'
            const response = await BffClientFactory().dossierInlichtingen_Post(inlichtingAanvulling)
            inlichtingId = response.inlichtingId
          }
          // Raise dit event voordat de bijlagen worden verwerkt.
          setTimeout(() => {
            EventBus.$emit('inlichting-saved', dossierId)
          }, 500)
          const bijlagenService = new VipInlichtingBijlagenService()
          await bijlagenService.save(dossierId, inlichtingId, this.bijlagenToUpload)
          this.dossierStore.dossierSetInlichtingLastChangedId(inlichtingId)
          this.$emit('saved')
        } catch (error) {
          catchBffError(error, errorMessage, true, true)
          this.$emit('error')
        } finally {
          this.isSaving = false
        }

        return inlichtingId
      }
      this.$emit('upload4', JSON.stringify(this.bijlagenToUpload))
    },
    validateInlichting (inlichtingAttributen: InlichtingAttribuut[], vanToepassing: boolean) {
      this.alertStore.removeAlerts([this.alertId])
      inlichtingAttributen.forEach(attribuut => {
        attribuut.errorMessage = ''
      })
      let errorFound = false

      if (vanToepassing === null) {
        this.alertStore.addAlert({
          id: this.alertId,
          closable: true,
          method: AlertMethod.Toast,
          title: 'Fout',
          message: '\'Van toepassing\' is verplicht',
          type: AlertType.Error,
          icon: AlertIcons.AlertCheck
        })
      } else if (vanToepassing === true) {
        inlichtingAttributen
        // Meta data moet niet worden gevalideerd.
          .filter(a => !a.isMetaData)
          .forEach(attribuut => {
            // Valideer alle attributen.
            if (!this.validateAttribuut(attribuut)) {
              errorFound = true
            }
          })

        if (!errorFound) {
          // Verplichte velden zijn gevalideerd.  Trigger event voor eventuele afhandeling van custom validatie.
          const validationEventArgs = { isValid: true }
          // Opmerking: dit event kan enkel worden opgevangen door de component die deze mixin gebruikt via 'export default mixins(vipInlichting).extend'.
          this.$emit('vip-inlichting-validate', validationEventArgs)

          if (!validationEventArgs.isValid) {
            errorFound = true
          }
        }
      }

      if (errorFound) {
        this.validationFailed = true
        return false
      } else {
        return true
      }
    },
    validateAttribuut (attribuut: InlichtingAttribuut) {
      let isValid = true

      if (attribuut.required) {
        isValid = this.validateRequiredAttribute(attribuut)
      }

      if (isValid) {
        if (_vipInlichtingMapper.isSetOfComplexTypeAttributen(attribuut.value) && !this.validateAttribuutSet(attribuut)) {
          isValid = false
        } else if (_vipInlichtingMapper.isDatePeriodAttribuut(attribuut)) {
          const datePeriod = attribuut as InlichtingDatePeriodAttribuut
          isValid = this.validateVanTotDatumPeriode(datePeriod.dateFrom, datePeriod.dateUntil)
        } else if (this.isMinimumLengthAttribuut(attribuut)) {
          isValid = this.validateMinimumLengthField(attribuut)
          if (!isValid) {
            attribuut.errorMessage = attribuut.label + ' voldoet niet aan de minimum lengte (' + attribuut.minimumLength + ')'
          }
        } else if (this.isConstraintAttribuut(attribuut)) {
          isValid = this.validateInputConstraint(attribuut)
          if (!isValid) {
            attribuut.errorMessage = attribuut.label + ' ' + attribuut.inputConstraint.errorMessage
          }
        }
      }
      return isValid
    },
    validateRequiredAttribute (attribuut: InlichtingAttribuut) {
      const attribuutType = (typeof attribuut.value) as string

      if (
      // Bevat array elementen?
        (Array.isArray(attribuut.value) && attribuut.value.length === 0) ||
          // Is het een geldige datum?
          (attribuut.value instanceof Date && !isFinite(attribuut.value.getMilliseconds())) ||
          (attribuutType === 'CodelijstElement' && !(attribuut.value as CodelijstElement).value) ||
          // Primitief type
          (attribuut.value === null) || attribuut.value === '') {
        attribuut.errorMessage = attribuut.label + ' is verplicht.'
        return false
      } else {
        return true
      }
    },
    validateAttribuutSet (attribuut: InlichtingAttribuut): boolean {
      let isValid = true
      if (_vipInlichtingMapper.isSetOfComplexTypeAttributen(attribuut.value) && !attribuut.name.includes('MetaData')) {
        for (const attribuutSet of attribuut.value) {
          for (const inlichtingAttribuut of attribuutSet) {
            if (_vipInlichtingMapper.isSetOfComplexTypeAttributen(inlichtingAttribuut.value) && !inlichtingAttribuut.name.includes('MetaData')) {
              for (const subSet of inlichtingAttribuut.value) {
                for (const attr of subSet) {
                  if (!this.validateAttribuut(attr)) {
                    isValid = false
                  }
                }
              }
            } else if (!this.validateAttribuut(inlichtingAttribuut)) {
              isValid = false
            }
          }
        }
      }
      return isValid
    },
    validateInputConstraint (attribuut: InlichtingAttribuut): boolean {
      if (attribuut.inputConstraint && attribuut.inputConstraint.regex) {
        if (attribuut.value) {
          return attribuut.value.toString().match(attribuut.inputConstraint.regex)
        } else {
          return !attribuut.required
        }
      }
      return true
    },
    validateMinimumLengthField (minLengthAttribute: InlichtingAttribuut) {
      if (minLengthAttribute.minimumLength && minLengthAttribute.minimumLength > 0) {
        if (minLengthAttribute.value) {
          return minLengthAttribute.value.toString().length >= minLengthAttribute.minimumLength
        } else {
          return !minLengthAttribute.required
        }
      }
      return true
    },
    validateVanTotDatumPeriode (vanDatumAttribuut: InlichtingAttribuut, totEnMetDatumAttribuut: InlichtingAttribuut) {
      // 'Geldig van' moet voor 'Geldig tot en met' komen.
      // Datums komende van de datepicker zitten in een array?!
      const vanDatum = vanDatumAttribuut.value?.[0]
      const totEnMetDatum = totEnMetDatumAttribuut.value?.[0]
      let isValid = false

      if (!isNaN(vanDatum ?? 'x') && !isNaN(totEnMetDatum ?? 'x')) {
        const vanDatum1 = vanDatum as Date
        const totEnMetDatum1 = totEnMetDatum as Date
        // Vergelijk enkel de datum, tijdstip is niet belangrijk.
        const vanDatumDateOnly = new Date(vanDatum1.getFullYear(), vanDatum1.getMonth(), vanDatum1.getDate())
        const totDatumDateOnly = new Date(totEnMetDatum1.getFullYear(), totEnMetDatum1.getMonth(), totEnMetDatum1.getDate())
        if (vanDatumDateOnly <= totDatumDateOnly) {
          isValid = true
        }
      } else {
        isValid = true
      }

      if (isValid) {
        vanDatumAttribuut.errorMessage = ''
      } else {
        // eslint-disable-next-line quotes
        vanDatumAttribuut.errorMessage = `'${vanDatumAttribuut.label}'  moet voor '${totEnMetDatumAttribuut.label}' komen.`
      }
      return isValid
    },
    savedHandler () {
      this.$emit('saved')
    },
    removedHandler () {
      this.$emit('removed')
    },
    herbevraagdHandler () {
      this.$emit('saved')
    },
    cancel () {
      this.restoreInlichting(this.inlichtingClone, this.inlichting);
      (this.$refs.inlichtingAccordion as VipInlichtingAccordion).opened = false
    },
    async remove () {
      const dossierId = this.dossierStore.dossierModuleGetDossierId
      await BffClientFactory().dossierInlichtingen_Delete(dossierId, this.inlichting.inlichtingId)
        .then(() => {
          this.$emit('removed')
          EventBus.$emit('close-modal', 'vl-modal-backdrop')
        })
        .catch((error) => {
          this.$emit('removed')
          catchBffError(error, 'verwijderen-inlichting-error', true)
        })
    },
    isMinimumLengthAttribuut (attribuut: InlichtingAttribuut): boolean {
      return attribuut.minimumLength !== null && attribuut.minimumLength !== undefined && attribuut.minimumLength > 0
    },
    isConstraintAttribuut (attribuut: InlichtingAttribuut): boolean {
      return attribuut.inputConstraint && attribuut.inputConstraint.regex !== null && attribuut.inputConstraint.regex !== undefined && attribuut.inputConstraint.regex !== ''
    },
    clonelichting () {
      this.inlichtingClone = this.inlichting ? JSON.parse(JSON.stringify(this.inlichting)) : null
    },
    restoreInlichting (source: InlichtingComponentData, target: InlichtingResponse) {
      target.vanToepassing = source.vanToepassing
      source.attributen.forEach(sourceAttribuut => {
        const targetAttribute = target.attributen.find(a => a.name === sourceAttribuut.name)
        switch (typeof targetAttribute.value) {
          case 'string':
          case 'number':
          case 'boolean':
            targetAttribute.value = sourceAttribuut.value
            break
          default:
            if (Array.isArray(targetAttribute.value) && sourceAttribuut.value && sourceAttribuut.value.length > 0) {
              targetAttribute.value.length = 0
              if (Array.isArray(sourceAttribuut)) {
                sourceAttribuut.value.forEach((element: unknown) => {
                  targetAttribute.value.push(element)
                })
              } else {
                targetAttribute.value.push(sourceAttribuut.value)
              }
            } else if (targetAttribute.value instanceof Date) {
              targetAttribute.value = new Date(Date.parse(sourceAttribuut.value))
            } else {
              targetAttribute.value = sourceAttribuut.value
            }
        }
      })
      this.$emit('saved')
    },
    scrollInlichtingIntoView () {
      const element = (this.$refs[this.previewComponentRef] as Vue)
      if (element) {
        element.$el.scrollIntoView()
        window.scrollBy({ top: -160 })
      }
    }
  },
  created () {
    this.clonelichting()
    this.previewComponentRef = makeUniqueKey('attributesComponentRef')
  }
})
