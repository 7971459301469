import { ref } from 'vue'

export default function useInlichtingValidation (validationErrors: Map<string, string>) {
  const validationMap = ref(validationErrors)
  const hasError = (key: string): boolean => {
    return validationErrors.has(key) && !!validationMap.value.get(key)
  }

  const getError = (key: string): string => {
    if (!validationMap.value.has(key)) { return '' }
    return validationMap.value.get(key)
  }
  return {
    hasError,
    getError
  }
}
