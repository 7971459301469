import { computed, defineComponent, PropType, ref } from 'vue'
import VipInlichtingVanToepassing
  from '@/components/vip-inlichting/components/vip-inlichting-van-toepassing/vip-inlichting-van-toepassing.vue'
import {
  BeschermdDuingebiedInlichting,
  DossierStatus,
  Inlichting,
  TypeInlichting
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import { formatDate } from '@/infrastructure/filters/filters'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'

export default defineComponent({
  name: 'BeschermdDuingebied',
  components: { VipInlichtingVanToepassing, VipInlichtingPreviewNew },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props) {
    const vipInlichting = ref(props.inlichting as BeschermdDuingebiedInlichting)
    const inlichtingType = TypeInlichting.BeschermdDuingebied
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)

    const getLabel = (option: string): string => {
      return new Map([
        ['VOOR_HET_DUINGEBIED_BELANGRIJK_LANDBOUWGEBIED', 'Beschermd duingebied: Voor het duingebied belangrijk landbouwgebied'],
        ['BESCHERMD_DUINGEBIED', 'Beschermd duingebied']
      ]).get(option)
    }

    return {
      vipInlichting,
      inlichtingTitle,
      inlichtingType,
      formatDate,
      getLabel
    }
  }
})
