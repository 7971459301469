import { computed, defineComponent, PropType, ref } from 'vue'
import {
  DossierStatus, GrondverschuivingsgevoeligheidInlichting,
  Inlichting,
  TypeInlichting
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import { formatDate } from '@/infrastructure/filters/filters'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'

export default defineComponent({
  name: 'Grondverschuivingsgevoeligheid',
  components: { VipInlichtingPreviewNew },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props) {
    const previewData = ref(props.inlichting as GrondverschuivingsgevoeligheidInlichting)
    const inlichtingType = TypeInlichting.Grondverschuivingsgevoeligheid
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)

    const getLabel = (option: string): string => {
      return new Map([
        ['LAGE_GEVOELIGHEID_KLASSE_1', 'Lage gevoeligheid (klasse 1)'],
        ['MATIGE_GEVOELIGHEID_KLASSE_2', 'Matige gevoeligheid (klasse 2)'],
        ['HOGE_GEVOELIGHEID_KLASSE_3', 'Hoge gevoeligheid (klasse 3)'],
        ['ZEER_HOGE_GEVOELIGHEID_KLASSE_4', 'Zeer hoge gevoeligheid (klasse 4)'],
      ]).get(option)
    }

    return {
      previewData,
      inlichtingTitle,
      inlichtingType,
      formatDate,
      getLabel
    }
  }
})
