import { computed, defineComponent, PropType, readonly } from 'vue'
import {
  DossierStatus,
  Inlichting, RisicogrondInlichting,
  TypeInlichting
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import useInlichtingPreview from '@/components/vip-inlichting/new-version/composables/inlichting-preview.composable'

export default defineComponent({
  name: 'RisicogrondV1',
  components: { VipInlichtingPreviewNew },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props) {
    const { downloadUrl } = useInlichtingPreview()
    const previewData = readonly(props.inlichting as RisicogrondInlichting)
    const inlichtingType = TypeInlichting.Risicogrond
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)

    const getLabel = (key: string, option: string): string => {
      switch (key) {
        case 'inrichtingType':
          return new Map([
            ['ARAB', 'ARAB risico-inrichting'],
            ['VLAREM', 'Vlarem risico-inrichting'],
            ['OMGEVINGSVERGUNNING', 'In het kader van een omgevingsvergunning'],
            ['NIET_VERGUND', 'Niet vergunde risicoactiviteiten'],
          ]).get(option)
        case 'rubriekType':
          return new Map([
            ['VLAREBO_2008', 'Vlarebo 2008'],
            ['VLAREM', 'Vlarem'],
          ]).get(option)
        case 'vlareboCode':
          return new Map([
            ['CATEGORIE_A', 'Categorie A'],
            ['CATEGORIE_A_ASTERISK', 'Categorie A*'],
            ['CATEGORIE_B', 'Categorie B'],
            ['CATEGORIE_B_ASTERISK', 'Categorie B*'],
            ['CATEGORIE_C', 'Categorie C'],
            ['CATEGORIE_O', 'Categorie O'],
            ['CATEGORIE_S', 'Categorie S'],
          ]).get(option)
        case 'vlareboDescription':
          return new Map([
            ['CATEGORIE_A', 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement, en om de twintig jaar'],
            ['CATEGORIE_A_ASTERISK', 'Inrichting waarvoor: a) bij uitsluitend bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend bodemonderzoek verplicht is bij overdracht, onteigening, sluiting en faillissement; b) bij ondergrondse opslag of bij combinatie van ondergrondse of bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de twintig jaar'],
            ['CATEGORIE_B', 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement, en om de tien jaar'],
            ['CATEGORIE_B_ASTERISK', 'Inrichting waarvoor: a) bij uitsluitend bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de twintig jaar; b) bij ondergrondse opslag of bij combinatie van ondergrondse of bovengrondse opslag conform het Bodemdecreet van 27 oktober 2006 en het VLAREBO van 14 december 2007 een oriënterend onderzoek verplicht is bij overdracht, sluiting en faillissement en om de tien jaar'],
            ['CATEGORIE_C', 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement, en om de vijf jaar'],
            ['CATEGORIE_O', 'Oriënterend onderzoek verplicht bij overdracht, sluiting en faillissement'],
            ['CATEGORIE_S', 'De exploitant dient onder leiding van een bodemsaneringsdeskundige een oriënterend bodemonderzoek uit te laten voeren en het verslag ervan aan de OVAM te bezorgen op verschillende tijdstippen, zie Vlarem I bijlage 1'],
          ]).get(option)
        case 'schrappingReden':
          return new Map([
            ['ANDERE', 'Andere'],
            ['BIJKOMENDE_ONDERZOEK_MET_BEWIJSSTUKKEN', 'Bijkomend onderzoek met bewijsstukken andere'],
            ['FOUT_IN_PERCEEL_NOTATIE', 'Fout in perceelnotatie'],
            ['GEMOTIVEERDE_VERKLARING_BODEMSANERINGSDESKUNDIGE', 'Gemotiveerde verklaring bodemsaneringsdeskundige'],
            ['KADASTRALE_WIJZIGINGEN', 'Kadastrale wijzigingen'],
            ['MATERIELE_VERGISSING', 'Materiële vergissing'],
            ['NIET_MEER_INGEDEELD_ALS_RISICO', 'Niet meer ingedeeld als risicoactiviteit'],
            ['NOOIT_UITGEBAAT', 'Nooit uitgebaat'],
            ['ONTERECHTE_OPNAME_IN_VERGUNNING', 'Onterechte opname in vergunning'],
            ['RISICO_ACTIVITEIT_NOOIT_UITGEVOERD', 'Nooit uitgevoerd'],
            ['VERSCHILLEND_VAN_WERKELIJKE_TOESTAND', 'Vergunde toestand komt niet overeen met werkelijke toestand'],
          ]).get(option)
      }
    }

    return {
      previewData,
      inlichtingType,
      inlichtingTitle,
      getLabel,
      formatDate,
      formatNumber,
      downloadUrl
    }
  }
})
