import { computed, defineComponent, PropType, ref } from 'vue'
import {
  DossierStatus,
  Inlichting,
  OnroerendErfgoedInlichting,
  TypeInlichting
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import { formatDate } from '@/infrastructure/filters/filters'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'

export default defineComponent({
  name: 'OnroerendErfgoed',
  components: { VipInlichtingPreviewNew },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props) {
    const previewData = ref(props.inlichting as OnroerendErfgoedInlichting)
    const inlichtingType = TypeInlichting.OnroerendErfgoed
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)

    const getLabel = (option: string): string => {
      return new Map([
        ['BESCHERMD_MONUMENT', 'Monument, beschermd'],
        ['BESCHERMD_CULTUURHISTORISCH_LANDSCHAP', 'Cultuurhistorisch landschap, beschermd'],
        ['BESCHERMD_STADS_OF_DORPSGEZICHT', 'Stads- of dorpsgezicht, beschermd'],
        ['BESCHERMDE_ARCHEOLOGISCHE_SITE', 'Archeologische site, beschermd'],
        ['OVERGANGSZONE', 'Overgangszone'],
        ['BESCHERMD_STADS_OF_DORPSGEZICHT_INTRINSIEK_BESCHERMINGSTYPE', 'Stads- of dorpsgezicht, beschermd, intrinsiek'],
        ['BESCHERMD_STADS_OF_DORPSGEZICHT_ONDERSTEUNEND_BESCHERMINGSTYPE', 'Stads- of dorpsgezicht, beschermd, ondersteunend'],
        ['VASTGESTELD_BOUWKUNDIG_ERFGOED', 'Bouwkundig erfgoed, vastgesteld'],
        ['VASTGESTELD_LANDSCHAPSATLASRELICT', 'Landschapsatlasrelict, vastgesteld'],
        ['VASTGESTELDE_ARCHEOLOGISCHE_ZONE', 'Archeologische zone, vastgesteld'],
        ['VASTGESTELDE_HOUTIGE_BEPLANTING_MET_ERFGOEDWAARDE', 'Houtige beplanting met erfgoedwaarde, vastgesteld'],
        ['VASTGESTELDE_HISTORISCHE_TUIN_OF_PARK', 'Historische tuin of park, vastgesteld'],
        ['ERFGOEDLANDSCHAP', 'Erfgoedlandschap'],
        ['UNESCO_WERELDERFGOED_KERNZONE', 'Unesco werelderfgoed kernzone'],
        ['UNESCO_WERELDERFGOED_BUFFERZONE', 'Unesco werelderfgoed bufferzone'],
        ['GEBIED_GEEN_ARCHEOLOGIE_GEWESTELIJK', 'Gebied geen archeologie, gewestelijk'],
        ['GEBIED_GEEN_ARCHEOLOGIE_GEMEENTELIJK', 'Gebied geen archeologie, gemeentelijk'],
        ['VASTGESTELD_LANDSCHAPPELIJK_ERFGOED', 'Vastgesteld landschappelijk erfgoed']
      ]).get(option)
    }
    return {
      previewData,
      inlichtingType,
      inlichtingTitle,
      formatDate,
      getLabel
    }
  }
})
