import { computed, defineComponent, PropType } from 'vue'
import {
  BronInformatie,
  DossierStatus,
  Inlichting,
  InlichtingenIndicator,
  InlichtingInputInlichtingenIndicator,
  InlichtingRubriek,
  LokaalOnroerendErfgoedInlichting,
  LokaalOnroerendErfgoedInlichtingGevolg,
  LokaalOnroerendErfgoedInlichtingInlichtingType,
  LokaalOnroerendErfgoedInput,
  LokaalOnroerendErfgoedInputDiscriminator,
  LokaalOnroerendErfgoedInputGevolg,
  LokaalOnroerendErfgoedInputInlichtingType,
  TypeInlichting
} from '@/infrastructure/bff-client/bff-client'
import useEditableInlichting from '@/components/vip-inlichting/new-version/composables/editable-inlichting.composable'
import VipInlichtingVanToepassing
  from '@/components/vip-inlichting/components/vip-inlichting-van-toepassing/vip-inlichting-van-toepassing.vue'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import EventBus from '@/infrastructure/events/event-bus'
import { inlichtingen, InlichtingType } from '@/infrastructure/constants/inlichting-types-constants'

function mapInlichtingToInput (vipInlichting?: LokaalOnroerendErfgoedInlichting): LokaalOnroerendErfgoedInput {
  return {
    inlichtingenIndicator: vipInlichting === null ? InlichtingInputInlichtingenIndicator.NEE : vipInlichting.inlichtingenIndicator as unknown as InlichtingInputInlichtingenIndicator,
    externeDocumentatie: vipInlichting?.externeDocumentatie,
    discriminator: LokaalOnroerendErfgoedInputDiscriminator.LOKAAL_ONROEREND_ERFGOED_V1,
    inlichtingType: LokaalOnroerendErfgoedInputInlichtingType.LOKAAL_ONROEREND_ERFGOED,
    bijlagen: vipInlichting?.bijlagen,
    beschrijving: vipInlichting?.beschrijving,
    referentie: vipInlichting?.referentie,
    gevolg: vipInlichting?.gevolg as unknown as LokaalOnroerendErfgoedInputGevolg,
    type: vipInlichting?.type,
    toelichting: vipInlichting?.toelichting,
    geldigheidStartdatum: vipInlichting?.geldigheidStartdatum,
    geldigheidEinddatum: vipInlichting?.geldigheidEinddatum
  } as LokaalOnroerendErfgoedInput
}

function mapInputToInlichting (input: LokaalOnroerendErfgoedInput, inlichtingId: string): LokaalOnroerendErfgoedInlichting {
  return {
    inlichtingId,
    inlichtingenIndicator: input.inlichtingenIndicator as unknown as InlichtingenIndicator,
    inlichtingType: LokaalOnroerendErfgoedInlichtingInlichtingType.LOKAAL_ONROEREND_ERFGOED,
    externeDocumentatie: input.externeDocumentatie,
    bijlagen: input.bijlagen,
    bronInformatie: {
      informatieverstrekker: 'Lokaal bestuur',
      laatsteWijzigingTijdstip: new Date()
    } as BronInformatie,
    kaarten: [],
    rubriek: InlichtingRubriek.ONROEREND_ERFGOED,
    beschrijving: input.beschrijving,
    referentie: input.referentie,
    gevolg: input.gevolg as unknown as LokaalOnroerendErfgoedInlichtingGevolg,
    type: input.type,
    toelichting: input.toelichting,
    geldigheidStartdatum: input.geldigheidStartdatum,
    geldigheidEinddatum: input.geldigheidEinddatum
  } as LokaalOnroerendErfgoedInlichting
}

export default defineComponent({
  name: 'LokaalOnroerendErfgoed',
  components: { VipInlichtingVanToepassing },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props, { emit }) {
    const {
      refs,
      inlichtingKey,
      vipInlichting,
      inlichtingInput,
      canEdit,
      validationErrors,
      accordion,
      saving,
      addedBijlagen,
      bijlageUploaded,
      toggleIndicator,
      inlichtingToggled,
      cancelEdit,
      saveInlichting,
      validationFailed,
      downloadUrl
    } = useEditableInlichting(
      props.inlichting ??
        ({ inlichtingType: InlichtingType.LOKAAL_ONROEREND_ERFGOED } as unknown as LokaalOnroerendErfgoedInlichting),
      mapInlichtingToInput,
      mapInputToInlichting
    )

    const inlichtingType = TypeInlichting.LokaalOnroerendErfgoed
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)

    const validateInput = (): Map<string, string> => {
      const errors = new Map<string, string>()
      if (!!inlichtingInput.value.beschrijving === false) {
        errors.set('beschrijving', 'Onderwerp is verplicht')
      }
      if (!!inlichtingInput.value.gevolg === false) {
        errors.set('gevolg', 'Gevolg is verplicht')
      }
      return errors
    }

    const options = new Map([
      ['gevolg',
        new Map([
          ['INFORMATIEF', 'Informatief'],
          ['LOKAAL_JURIDISCH_VERANKERD', 'Lokaal juridisch verankerd']
        ])
      ],
      ['type',
        new Map([
          ['Erfgoedcluster', ''],
          ['Stadsinventaris', ''],
          ['Opgenomen in lokale inventaris met oog op gewestelijke inventarisatie', ''],
          ['Erfgoedstudie', ''],
          ['Erfgoed met lokaal belang', ''],
          ['Waardevol pand volgens BPA', ''],
          ['Ontwikkelingsprofiel (Actieplan bouwkundig erfgoed)', ''],
          ['Gemeentelijk monumentenplan', '']
        ])
      ]
    ])

    const saved = (inlichtingId: string) => {
      saveInlichting(inlichtingId)
      emit('saved', vipInlichting.value)
      inlichtingToggled(true)
    }

    const removed = () => {
      emit('removed', vipInlichting.value.inlichtingId)
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const cancelled = () => {
      cancelEdit()
      emit('cancelled')
    }

    return {
      vipInlichting,
      inlichtingInput,
      validationErrors,
      saving,
      accordion,
      refs,
      inlichtingKey,
      canEdit,
      addedBijlagen,
      inlichtingType,
      inlichtingTitle,
      options,
      bijlageUploaded,
      inlichtingToggled,
      toggleIndicator,
      validateInput,
      validationFailed,
      saved,
      removed,
      cancelled,
      downloadUrl,
      formatNumber,
      formatDate
    }
  }
})
