import { render, staticRenderFns } from "./minnelijke-schikking-preview.vue?vue&type=template&id=80c0056a"
import script from "./minnelijke-schikking-preview.ts?vue&type=script&lang=ts&external"
export * from "./minnelijke-schikking-preview.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports