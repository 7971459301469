import { computed, defineComponent, PropType, ref } from 'vue'
import {
  BeschermdWaterwinningsgebiedInlichting,
  DossierStatus,
  Inlichting,
  TypeInlichting
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import { formatDate } from '@/infrastructure/filters/filters'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'

export default defineComponent({
  name: 'BeschermdWaterwinningsgebied',
  components: { VipInlichtingPreviewNew },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props) {
    const previewData = ref(props.inlichting as BeschermdWaterwinningsgebiedInlichting)
    const inlichtingType = TypeInlichting.BeschermdWaterwinningsgebied
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)

    const getLabel = (option: string): string => {
      return new Map([
        ['WINNING', 'Waterwingebied'],
        ['ZONE_1_DE_24_URENZONE', 'Beschermingszone type I (24-urenzone)'],
        ['ZONE_2_BACTERIOLOGISCHE_ZONE', 'Beschermingszone type II (Bacteriologische zone)'],
        ['ZONE_3_DE_CHEMISCHE_ZONE', 'Beschermingszone type III (Chemische zone)'],
      ]).get(option)
    }

    return {
      previewData,
      inlichtingTitle,
      inlichtingType,
      formatDate,
      getLabel
    }
  }
})
