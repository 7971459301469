import { computed, defineComponent, PropType, ref } from 'vue'
import {
  DossierStatus,
  Inlichting, OverstromingsgebiedEnOeverzoneInlichting,
  TypeInlichting
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'

export default defineComponent({
  name: 'OverstromingsgebiedEnOeverzone',
  components: { VipInlichtingPreviewNew },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props) {
    const previewData = ref(props.inlichting as OverstromingsgebiedEnOeverzoneInlichting)
    const inlichtingType = TypeInlichting.OverstromingsgebiedEnOeverzone
    const inlichtingTitle = computed(() => {
      if (previewData.value.beschrijving?.includes('Overstromingsgebied')) {
        return 'Overstromingsgebied'
      }
      if (previewData.value.beschrijving?.includes('Oeverzone')) {
        return 'Oeverzone'
      }
      return inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label
    })

    const beschrijving = computed(() => {
      if (previewData.value.beschrijving.includes('Overstromingsgebied')) {
        return previewData.value.beschrijving.replace('Overstromingsgebied', '').trim()
      }
      if (previewData.value.beschrijving.includes('Oeverzone')) {
        return previewData.value.beschrijving.replace('Oeverzone', '').trim()
      }
      return previewData.value.beschrijving.trim()
    })

    return {
      previewData,
      inlichtingType,
      inlichtingTitle,
      beschrijving
    }
  }
})
