import { NavigationGuard } from 'vue-router'
import { IRoute, IRouteMeta } from './vip-router'
import { useUserStore } from '@/infrastructure/stores/user-store'
import pinia from '@/infrastructure/stores/store'
import { FinancieelBeheerder } from '@/infrastructure/constants/roles'

export const authHandler: NavigationGuard = async (to: IRoute, _from: IRoute, next) => {
  try {
    if (!to.meta?.authorize) {
      next()
      return
    }

    const userStore = useUserStore(pinia)

    const loginRedirect = await handleAuthentication(userStore, to)
    if (loginRedirect) {
      next(loginRedirect)
      return
    }

    const authorizationRedirect = handleUserRoles(userStore, to)
    if (authorizationRedirect) {
      next(authorizationRedirect)
      return
    }

    next()
  } catch (error) {
    console.error('Navigation error:', error)
    next(false) // Cancel current navigation
  }
}

const handleAuthentication = async (userStore: any, targetRoute: IRoute): Promise<object | null> => {
  if (!userStore.userModuleGetUserInfo) {
    const isAuthenticated = await userStore.userCheckAuthenticated()
    if (isAuthenticated) {
      await userStore.userModuleDispatchUpdateUser()
      return null
    } else {
      return { name: 'login', params: { redirect: targetRoute.path } }
    }
  }
  return null
}

const isForbidden = (userStore: any, targetRoute: IRoute): boolean => {
  return !userStore.userModuleHasRole((targetRoute.meta as IRouteMeta)?.authorize?.roles)
}

const handleUserRoles = (userStore: any, originPath: IRoute): string => {
  const userRoles = userStore.userModuleGetRoles
  if (userRoles !== undefined) {
    const userIsFinancial = userStore.userModuleHasRole([FinancieelBeheerder])
    if (userRoles.length === 1 && userIsFinancial && originPath.path !== '/financieel') {
      return '/financieel'
    } else if (isForbidden(userStore, originPath)) {
      return originPath.path
    }
  }
}
