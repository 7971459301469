import { computed, defineComponent, PropType, ref } from 'vue'
import VipInlichtingVanToepassing
  from '@/components/vip-inlichting/components/vip-inlichting-van-toepassing/vip-inlichting-van-toepassing.vue'
import {
  DossierStatus,
  Inlichting,
  LeegstaandeEnVerwaarloosdeBedrijfsruimtenInlichtingV2,
  RestLeegstandIndicatie, TypeInlichting
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import VipInlichtingAccordion
  from '@/components/vip-inlichting/components/vip-inlichting-accordion/vip-inlichting-accordion.vue'

export default defineComponent({
  name: 'LeegstaandeEnVerwaarloosdeBedrijfsruimten',
  components: { VipInlichtingVanToepassing, VipInlichtingPreviewNew },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props) {
    const title = ref(null)
    const vipInlichting = ref(props.inlichting as LeegstaandeEnVerwaarloosdeBedrijfsruimtenInlichtingV2)
    const accordion = ref<InstanceType<typeof VipInlichtingAccordion>>(null)
    const inlichtingType = TypeInlichting.LeegstaandeEnVerwaarloosdeBedrijfsruimtenV2

    const inlichtingTitle = computed(() => {
      if (vipInlichting.value.leegstandIndicatie === RestLeegstandIndicatie.NEE) {
        if (vipInlichting.value.verwaarlozingIndicatie) {
          return 'Verwaarloosde bedrijfsruimte'
        }
        return 'Leegstaande en/of verwaarloosde bedrijfsruimten'
      } else if (vipInlichting.value.leegstandIndicatie === RestLeegstandIndicatie.DEELS) {
        if (vipInlichting.value.verwaarlozingIndicatie) {
          return 'Verwaarloosde en deels leegstaande bedrijfsruimte'
        }
        return 'Deels leegstaande bedrijfsruimte'
      } else {
        if (vipInlichting.value.verwaarlozingIndicatie) {
          return 'Leegstaande en verwaarloosde bedrijfsruimte'
        }
        return 'Leegstaande bedrijfsruimte'
      }
    })

    return {
      title,
      inlichtingType,
      vipInlichting,
      accordion,
      inlichtingTitle,
    }
  }
})
