import { computed, defineComponent, PropType, ref } from 'vue'
import {
  DossierStatus,
  Inlichting,
  TypeInlichting, VogelrichtlijngebiedInlichting
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'

export default defineComponent({
  name: 'Vogelrichtlijngebied',
  components: { VipInlichtingPreviewNew },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props) {
    const previewData = ref(props.inlichting as VogelrichtlijngebiedInlichting)
    const inlichtingType = TypeInlichting.Vogelrichtlijngebied
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)

    const getLabel = (option: string): string => {
      return new Map([
        ['INTEGRAAL', 'met integraal behoud'],
        ['NIET_INTEGRAAL', 'zonder integraal behoud'],
      ]).get(option)
    }

    return {
      previewData,
      inlichtingType,
      inlichtingTitle,
      getLabel
    }
  }
})
