import { defineComponent, ref, watch } from 'vue'
import VipDatePicker from '@/components/vip-date-picker/vip-date-picker.vue'

export default defineComponent({
  name: 'vip-date-picker-wrapper',
  components: { VipDatePicker },
  props: {
    placeholder: {
      default: 'dd/mm/jjjj',
      type: String
    },
    visualFormat: {
      default: 'd/m/Y',
      type: String
    },
    modError: Boolean,
    id: String,
    value: undefined
  },
  setup: function (props, { emit }) {
    const pickedDate = ref(props.value ? [new Date(props.value)] : null)

    watch(pickedDate, () => {
      emit('input', pickedDate.value[0])
    })

    return {
      pickedDate
    }
  }
})
