import { computed, defineComponent, PropType, ref } from 'vue'
import {
  DossierStatus,
  Inlichting, SignaalgebiedInlichting,
  TypeInlichting
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import { formatDate } from '@/infrastructure/filters/filters'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'

export default defineComponent({
  name: 'Signaalgebied',
  components: { VipInlichtingPreviewNew },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props) {
    const previewData = ref(props.inlichting as SignaalgebiedInlichting)
    const inlichtingType = TypeInlichting.Signaalgebied
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)

    const getLabel = (key: string, option: string): string => {
      switch (key) {
        case 'bekken':
          return new Map([
            ['IJZERBEKKEN', 'Ijzerbekken'],
            ['BEKKEN_VAN_DE_BRUGSE_POLDERS', 'Bekken van de Brugse Polders'],
            ['BEKKEN_VAN_DE_GENTSE_KANALEN', 'Bekken van de Gentse Kanalen'],
            ['BENEDENSCHELDEBEKKEN', 'Benedenscheldebekken'],
            ['LEIEBEKKEN', 'Leiebekken'],
            ['BOVENSCHELDEBEKKEN', 'Bovenscheldebekken'],
            ['DENDERBEKKEN', 'Denderbekken'],
            ['DIJLE_ZENNEBEKKEN', 'Dijle- en Zennebekken'],
            ['DEMERBEKKEN', 'Demerbekken'],
            ['NETEBEKKEN', 'Netebekken'],
            ['MAASBEKKEN', 'Maasbekken']
          ]).get(option)
        case 'bestemming':
          return new Map([
            ['BOUWVRIJE_OPGAVE', 'Bouwvrije opgave'],
            ['VERSCHERPTE_WATERTOETS', 'Verscherpte watertoets']
          ]).get(option)
      }
    }
    return {
      previewData,
      inlichtingType,
      inlichtingTitle,
      formatDate,
      getLabel
    }
  }
})
