import Vue from 'vue'
import WKT from 'ol/format/WKT'
import GeoJSON from 'ol/format/GeoJSON'
import shp, { combine, parseShp } from 'shpjs'
import { AlertIcons } from '@/infrastructure/stores/alert-store'

export enum UploadError {
  InvalidFileExtension = 0,
  WktParsingError = 1,
  GeoJsonParsingError = 2,
  ShpParsingError = 3
}

export default Vue.extend({
  name: 'VipKaartImport',
  computed: {
    AlertIcons () {
      return AlertIcons
    },
    alertMessage (): string {
      switch (this.errorCode) {
        case UploadError.InvalidFileExtension:
          return 'Er is een bestand geupload met een niet toegelaten extensie'
        case UploadError.GeoJsonParsingError:
          return 'Er is een fout opgetreden bij het verwerken van het GeoJSON bestand. Controleer de inhoud van het bestand en probeer opnieuw.'
        case UploadError.ShpParsingError:
          return 'Er is een fout opgetreden bij het verwerken van de Shapefile. Controleer de inhoud van het bestand en probeer opnieuw.'
        case UploadError.WktParsingError:
          return 'Er is een fout opgetreden bij het verwerken van het WKT bestand. Controleer de inhoud van het bestand en probeer opnieuw.'
      }
      return ''
    }
  },
  data () {
    return {
      isUploading: false,
      errorCode: null
    }
  },
  methods: {
    closeError () {
      this.errorCode = null
    },
    processFileAdded (file: File) {
      this.isUploading = true
      this.errorCode = null
      this.readFile(file)
    },
    async readFile (file: File) {
      const fileExtension = file.name
        .split('.')
        .pop()
        .toLowerCase()
      const reader = new FileReader()
      switch (fileExtension) {
        case 'wkt':
        case 'json':
          reader.readAsText(file, 'UTF-8')
          break
        case 'zip':
        case 'shp':
          reader.readAsArrayBuffer(file)
          break
        default:
          this.errorCode = UploadError.InvalidFileExtension
          this.isUploading = false
      }
      reader.onload = () => {
        this.processImport(reader.result, fileExtension)
        this.isUploading = false
      }
    },
    parseGeoJson (input: any): string {
      const geoJson = new GeoJSON()
      const parsed = geoJson.readFeature(input)
      const wkt = new WKT()
      return wkt.writeFeature(parsed)
    },
    async processImport (input: any, fileExtension: string) {
      let wktResult = ''
      switch (fileExtension) {
        case 'wkt':
          try {
            const wkt = new WKT()
            const parsed = wkt.readGeometry(input)
            wktResult = wkt.writeGeometry(parsed)
          } catch {
            this.errorCode = UploadError.WktParsingError
          }
          break
        case 'json':
          try {
            wktResult = this.parseGeoJson(input)
          } catch (error) {
            this.errorCode = UploadError.GeoJsonParsingError
          }
          break
        case 'shp':
          try {
            const res = combine([parseShp(input)])
            wktResult = this.parseGeoJson(res.features[0].geometry)
          } catch (e) {
            this.errorCode = UploadError.ShpParsingError
          }
          break
        case 'zip':
          try {
            await shp(input).then(geojson => {
              wktResult = geojson.features[0].properties.wkt
              if (!wktResult) {
                wktResult = this.parseGeoJson(geojson.features[0].geometry)
              }
            })
          } catch (e) {
            this.errorCode = UploadError.ShpParsingError
          }
          break
        default:
          this.errorCode = UploadError.InvalidFileExtension
          break
      }
      if (wktResult) {
        wktResult = this.mergeDuplicateCoordinates(wktResult)
        this.$emit('openbaar-domein-added', wktResult)
      }
      ((this.$refs.upload as Vue).$refs.dropzoneRef as any).dropzone.removeAllFiles()
    },
    mergeDuplicateCoordinates (wkt: string): string {
      const coordinatesPattern = /\(\((.*)\)\)/
      const match = wkt.match(coordinatesPattern)

      if (!match || match.length < 2) {
        throw new Error('Invalid WKT format')
      }

      const coordinatesString = match[1]
      const coordinates = coordinatesString.split(',').map(coord => coord.trim())

      // First and last coordinates
      const firstCoord = coordinates[0]
      const lastCoord = coordinates[coordinates.length - 1]

      const mergedCoordinates = [firstCoord]
      const checkedCoordinates = [firstCoord, lastCoord]

      for (let i = 1; i < coordinates.length - 1; i++) {
        const currentCoordinateX = parseFloat(coordinates[i].split(' ')[0]).toFixed(4)
        const currentCoordinateY = parseFloat(coordinates[i].split(' ')[1]).toFixed(4)
        const currentCoordinate = `${currentCoordinateX} ${currentCoordinateY}`
        const previousCoordinateX = parseFloat(coordinates[i - 1].split(' ')[0]).toFixed(4)
        const previousCoordinateY = parseFloat(coordinates[i - 1].split(' ')[1]).toFixed(4)
        const previousCoordinate = `${previousCoordinateX} ${previousCoordinateY}`
        if (checkedCoordinates.includes(currentCoordinate)) {
          continue
        }
        checkedCoordinates.push(currentCoordinate)
        if (currentCoordinate !== previousCoordinate) {
          mergedCoordinates.push(coordinates[i])
        }
      }

      mergedCoordinates.push(lastCoord)

      return `POLYGON((${mergedCoordinates.join(',')}))`
    }
  }
})
