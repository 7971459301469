import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'vip-validation-wrapper',
  props: {
    for: String,
    label: String,
    annotation: String,
    error: String,
    modHiddenLabel: Boolean,
    modRequired: Boolean,
    modBlockLabel: Boolean,
    isError: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props) {
    const parsedLabel = computed(() => {
      return `${props.label ? props.label : '&nbsp;'}${
          props.modRequired ? '&nbsp;<span class="vl-u-text--error">*</span>' : ''
      }`
    })

    const forValue = computed((): string => {
      return props.for
    })

    return {
      parsedLabel,
      forValue
    }
  }
})
