import { computed, defineComponent, PropType, ref } from 'vue'
import { generateKey } from '@/infrastructure/helpers/general'
import { AutoCompleteResult, AutoCompleteSelection } from '@/components/ippro/vl-ippro-autocomplete/ippro-autocomplete'
import IpproAutocomplete from '@/components/ippro/vl-ippro-autocomplete/ippro-autocomplete.vue'

export default defineComponent({
  name: 'vip-suggestie-input',
  components: {
    IpproAutocomplete
  },
  props: {
    data: {
      type: Array as PropType<string[]>,
      default: () => [] as string[]
    },
    value: String,
    minLength: Number,
    limitWidth: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { emit }) {
    const searchComponentKey = generateKey()
    const convertedOptions = ref([] as AutoCompleteResult[])
    const inputValue = ref(props.value)
    const inputClass = computed(() => props.limitWidth ? 'map-search' : 'form-search')
    const setSelected = (item: AutoCompleteSelection) => {
      if (item == null || item.name == null) {
        return
      }
      inputValue.value = item.name
      emit('input', item.name)
    }

    const onInput = () => {
      emit('input', inputValue.value)
      if (inputValue.value.length > props.minLength) {
        convertedOptions.value = props.data.filter(f => f.toLowerCase().includes(inputValue.value.toLowerCase())).map(m => convertToAutoCompleteResult(m))
      } else {
        convertedOptions.value = props.data.map(m => convertToAutoCompleteResult(m))
      }
    }

    const convertToAutoCompleteResult = (label: string): AutoCompleteResult => {
      const selection = { obj: label, name: label } as AutoCompleteSelection
      return { title: label, value: selection } as AutoCompleteResult
    }

    const loadData = () => {
      convertedOptions.value = props.data.map(m => convertToAutoCompleteResult(m))
    }

    return {
      searchComponentKey,
      convertedOptions,
      inputValue,
      inputClass,
      setSelected,
      onInput,
      loadData
    }
  }
})
