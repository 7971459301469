import { defineComponent, PropType, ref, watch } from 'vue'
import { Aanmaning } from '@/infrastructure/bff-client/bff-client'
import useHandhavingsinstrument
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/composable/handhavingsinstrument.composable'
import useStedenbouwkundigeOvertredingLabels
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/composable/stedenbouwkundige-overtreding-labels.composable'
import { HandhavingsinstrumentType } from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/edit/procedure/procedure-edit'
import useInlichtingValidation
  from '@/components/vip-inlichting/new-version/composables/inlichting-validation.composable'

export default defineComponent({
  name: 'aanmaning-edit',
  props: {
    instrument: Object as PropType<Aanmaning>,
    procedureIndex: Number,
    instrumentIndex: Number,
    validationErrors: Map<string, string>,
    modDisabled: Boolean
  },
  setup (props, { emit }) {
    const { makeUnique } = useHandhavingsinstrument()
    const { options, getLabel } = useStedenbouwkundigeOvertredingLabels()
    const { hasError } = useInlichtingValidation(props.validationErrors)

    const aanmaningEdit = ref(props.instrument as Aanmaning)

    watch(() => aanmaningEdit.value, (newValue) => {
      emit('instrument-changed', { index: props.instrumentIndex, type: HandhavingsinstrumentType.Aanmaning, value: newValue })
    })

    return {
      aanmaningEdit,
      options,
      hasError,
      makeUnique,
      getLabel,
    }
  }
})
