import EventBus from '@/infrastructure/events/event-bus'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'vip-inlichting-actions',
  props: {
    modDisabled: Boolean,
    modSaving: Boolean,
    submitButtonLabel: {
      default: 'Bewaren',
      type: String
    },
    canDelete: Boolean,
    invalid: Boolean
  },
  setup (_, { emit }) {
    const removeModalopen = ref(false)
    const isRemoving = ref(false)
    const modalZindex = ref(402300)
    const length = ref(0)

    const save = () => {
      emit('save')
    }

    const cancel = () => {
      emit('cancel')
      emit('cancelled')
    }

    const remove = () => {
      removeModalopen.value = true
    }

    const modalClosed = () => {
      removeModalopen.value = false
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const removeInlichting = () => {
      isRemoving.value = true
      emit('remove')
    }

    return {
      removeModalopen,
      isRemoving,
      modalZindex,
      length,
      save,
      cancel,
      remove,
      modalClosed,
      removeInlichting
    }
  }
})
